import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';
import { SETTLEMENT_TYPE } from '@settlement/models';

export default{
    getCdfMainId(loanId) { return HttpWrapper.get(`${endpoints.SETTLEMENT_STATEMENT}/cdfMainId/${loanId}`); },
    getSSDashboardData (loanId, settlementType) { return HttpWrapper.get(`${endpoints.SETTLEMENT_STATEMENT}/dashboardData/${loanId}/settlementType/${settlementType}`); },
    getMissingSettlementItems (loanId, settlementType) { return HttpWrapper.get(`${endpoints.SETTLEMENT_STATEMENT}/missingItems/${loanId}/settlementType/${settlementType}`); },
    mapSettlementData(settlementType, loanId) {
        switch (settlementType) {
            case SETTLEMENT_TYPE.CDF:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf`);
            case SETTLEMENT_TYPE.HUD_1974:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud1974`);
            case SETTLEMENT_TYPE.HUD_2010:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud2010`);
        }
    },
    mapSettlementDataOverride(settlementType, loanId) {
        switch (settlementType) {
            case SETTLEMENT_TYPE.CDF:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf/mapOverride`);
            case SETTLEMENT_TYPE.HUD_1974:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud1974/mapOverride`);
            case SETTLEMENT_TYPE.HUD_2010:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud2010/mapOverride`);
        }
    },    
    resetSettlementData(orderId, loanId) {
        return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${orderId}/loan/${loanId}/resetSettlementStatements`);
    }
}