import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    get(){
        return HttpWrapper.get(`${endpoints.FILENUMBERPOOLS}`);
    },
    save (fileNumberPool, changes) {
        return HttpWrapper.put(`${endpoints.FILENUMBERPOOLS}`, { data: fileNumberPool, changes} );
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.FILENUMBERPOOLS}/delete`, ids);
    },
};
