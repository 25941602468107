import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getAll(){
        return HttpWrapper.get(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}`);
    },
    get(id){
        return HttpWrapper.get(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}/${id}`);
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}/delete`, ids);
    },
    save (workflowProcessTemplate, changes) {
        return HttpWrapper.put(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}`, { data: workflowProcessTemplate, changes} );
    },
    applyToExisting (id) {
        return HttpWrapper.get(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}/applyToExisting/${id}` );
    },
    toggleIsInactive(workflowProcessTemplatesIds) {
        return HttpWrapper.post(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}/toggleisinactive`, workflowProcessTemplatesIds);
    },
    copyWorkflowProcessTemplate (workflowProcessTemplate) {
        return HttpWrapper.put(`${endpoints.WORKFLOW_PROCESS_TEMPLATES}/copyWorkflowProcessTemplate`, workflowProcessTemplate );
    },
};
