import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    getLookups () {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/lookups/`);
    },

    getOrderDocumentContent (orderDocumentId) {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/orderdocuments/${orderDocumentId}/content`, null);
    },

    getMergedDocumentContent (request) {
        //request.mergeEngineId = window.localStorage.getItem("mergeEngineId");
        request.mergeEngineId = _.get(appSettings, "mergeEngineId", 1);
        const apiUrl = `${endpoints.MERGEDOCS}/mergedocs/`;
        return HttpWrapper.post(apiUrl, request, null);
    },

    getMergeFieldTree(parentItem) {
        return _.isNil(parentItem)
            ? HttpWrapper.get(`${endpoints.MERGEDOCS}/fields/`) //Gets entire tree (2-3Mb response)
            : HttpWrapper.post(`${endpoints.MERGEDOCS}/fields/`, parentItem); //Gets only item children
    },

    search(searchRequest) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/search`, searchRequest);
    },

    addDocumentToOrder (documentId, orderId) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/${documentId}/assigntoorder/${orderId}`);
    },

    addDocumentsToOrder (documentIds, orderId) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/assigntoorder/${orderId}`, documentIds);
    },

    addPackageToOrder (packageId, orderId) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/package/${packageId}/assigntoorder/${orderId}`);
    },

    addPackagesToOrder (packageIds, orderId) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/package/assigntoorder/${orderId}`, packageIds);
    },

    removeDocumentFromOrder (orderDocumentId) {
        return HttpWrapper.delete(`${endpoints.DOCUMENTS}/orderdocuments/${orderDocumentId}/delete`);
    },

    removePackageFromOrder (packageId, orderId) {
        return HttpWrapper.delete(`${endpoints.DOCUMENTS}/package/${packageId}/deletefromorder/${orderId}`);
    },

    getStandardLanguages (searchRequest) {
        //if (regionId > 0) {return HttpWrapper.get(`${endpoints.DOCUMENTS}/standardlanguages/byregion/${regionId}`);}
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/standardlanguages`,searchRequest);
    },

    getStandardLanguageCategories(){
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/standardlanguagecategories`);
    },

    getStandardLanguageContent(standardLanguageId){
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/standardlanguagecontent/${standardLanguageId}`);
    },

    getStandardLanguageListContent({ standardLanguageIDs=[], standardLanguages=[], linesBetweenClauses=null, ordersID=0, loanID=0 }, contentType = "rtf") {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/standardlanguagecontent/${contentType}`, { standardLanguageIDs, standardLanguages, linesBetweenClauses, ordersID, loanID });
    },

    getOrderDocumentPrompts (orderDocumentIds) {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/orderdocuments/prompts/${orderDocumentIds}`);
    },

    getStandardLanguageSubPrompts (standardLanguage, orderDocumentId, orderId) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/standardLanguages/prompts/`, {dto: standardLanguage, orderId, orderDocumentId});
    },

    getUnsavedOrderDocumentPrompts (orderId, orderDocumentIds) {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/orderdocuments/${orderId}/prompts/${orderDocumentIds}`);
    },

    getSavedPromptsForOrder (orderId) {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/orderdocuments/${orderId}/savedprompts/`);
    },

    updateSavedPrompts (saveRequest) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/orderdocuments/savedprompts/`, saveRequest);
    },

    savedPromptValue (prompt) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/orderdocuments/prompt/`, prompt);
    },

    deleteSavedPromptValue (id) {
        return HttpWrapper.delete(`${endpoints.DOCUMENTS}/orderdocuments/prompt/${id}`);
    },

    convertDocuments(request) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/convert/`, request);
    },

    processForDownload (request) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/processdownload/`, request);
    },

    processForPrintAll (request) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/processprintall/`, request);
    },

    updateLastPrintDate (id) {
        return HttpWrapper.put(`${endpoints.DOCUMENTS}/orderdocuments/lastprint/${id}`);
    },

    applyWatermark(request){
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/applyWatermark`, request);
    },

    directConversion(ext, formData) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/convert-to/${ext}`, formData, null);
    },

    directConversionDownload(ext, formData) {
        return HttpWrapper.post(`${endpoints.DOCUMENTS}/convert-to/${ext}/download`, formData, null);
    },

    testApplyWatermark() {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/test-apply-watermark`);
    },

    getCachedDocument(cacheKey, fileName) {
        return HttpWrapper.get(`${endpoints.DOCUMENTS}/content/${cacheKey}/${fileName}`);
    },
};
