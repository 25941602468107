import { HttpWrapper } from '../shared/services/rest.service';
import { DOCUMENT_TEMPLATES } from "./endpoints";

export default {
    getList(){ return HttpWrapper.get(`${DOCUMENT_TEMPLATES}`); },
    getListVisible(){ return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/visible`); },
    getByPackageId(packageId){ return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/package/${packageId}`); },
    getByCategoryId(categoryId){ return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/category/${categoryId}`); },
    getByState(state) {
        return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/${state}/state/`, null);
    },
    save (data) { return HttpWrapper.post(DOCUMENT_TEMPLATES, data, null); },
    saveContent (data) { return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/content/`, data, null); },
    saveStates (data) { return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/states/`, data, null); },
    search (data) {
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/search`, data, null);
    },
    getDocumentTemplate (id) {
        return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/${id}`);
    },
    getDefaultTypeDocumentTemplate(id) {
        return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/${id}/default`);
    },
    upload(id, formData, targetFormatOnly=false) {
        let targetFormatEndpoint = targetFormatOnly ? "/target" : "";
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/${id}/upload${targetFormatEndpoint}`, formData, null);
    },
    processDownload(data) {
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/download`, data, null);
    },
    delete (ids) {
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/delete/`, ids, null);
    },
    validate(data) {
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/validate`, { data });
    },
    getWatermarks(id){
        return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/${id}/watermarks`);
    },
    saveWatermarks(id,data){
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/${id}/watermarks`, data);
    },
    getPrompts(data){
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/prompts/`, data, null);
    },
    generateFormatSample(data){
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/formatsample/`, data, null);
    },
    getAvailableStoredProcedures(){
        return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/availablestoredprocedures/`);
    },
    fillStoredProcedure(sp){
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/storedprocedure/`, sp, null);
    },
    getDocumentStoredProcedures(data){
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/docstoredprocedures/`, data, null);
    },
    getDocumentStandardLanguagePrompts(data){
        return HttpWrapper.post(`${DOCUMENT_TEMPLATES}/docstandardlanguageprompts/`, data, null);
    },
    getTags(id){
        return HttpWrapper.get(`${DOCUMENT_TEMPLATES}/${id}/tags`);
    },
};
