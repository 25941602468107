import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    get(){
        return HttpWrapper.get(`${endpoints.POLICYPOOLS}`);
    },
    save (policyPools, changes) {
        return HttpWrapper.put(`${endpoints.POLICYPOOLS}`, { data: policyPools, changes} );
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.POLICYPOOLS}/delete`, ids);
    },
    activate(ids) {
        return HttpWrapper.post(`${endpoints.POLICYPOOLS}/activate`, ids);
    },
    // delete (policyPoolsId) {
    //     return HttpWrapper.delete(`${endpoints.POLICYPOOLS}/${policyPoolsId}`);
    // },
};
