import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default{
    getByLoanId (loanId) { return HttpWrapper.get(`${endpoints.DEBIT_CREDITS}/loan/${loanId}`); },
    getCashToCloseData (loanId) { return HttpWrapper.get(`${endpoints.DEBIT_CREDITS}/loan/${loanId}/cashToClose`); },
    update(orderId, data){ return HttpWrapper.put(`${endpoints.DEBIT_CREDITS}/order/${orderId}`, data); },
    mapData(loanId) { return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf`); },
    getOverrideStatus(orderId){ return HttpWrapper.get(`${endpoints.DEBIT_CREDITS}/order/${orderId}`) },
    createAutoDebitCredit(orderId, data){ return HttpWrapper.put(`${endpoints.DEBIT_CREDITS}/order/${orderId}/createAutoDC`, data, null); },
}