import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    activate (ids) {
        return HttpWrapper.post(`${endpoints.WORKFLOWDEPARTMENTS}/activate`, ids);
    },
    getWorkflowDepartments(){
        return HttpWrapper.get(`${endpoints.WORKFLOWDEPARTMENTS}`)
    },
    saveWorkflowDepartment (workflowDepartment) {
        return HttpWrapper.put(`${endpoints.WORKFLOWDEPARTMENTS}`, workflowDepartment );
    },
    deleteWorkflowDepartment (workflowDepartmentID) {
        return HttpWrapper.delete(`${endpoints.WORKFLOWDEPARTMENTS}/${workflowDepartmentID}`);
    },
    updateDashboardExclusions (ids) {
        return HttpWrapper.post(`${endpoints.WORKFLOWDEPARTMENTS}/dashboard`, ids);
    }

};
