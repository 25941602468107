import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getFileScanCategoryTasks(){
        return HttpWrapper.get(`${endpoints.FILESCANDESCRIPTIONTASKS}`);
    },
    save (data) { return HttpWrapper.post(endpoints.FILESCANDESCRIPTIONTASKS, data, null); },
    saveFileScanCategoryTasks (fileScanCategoryTasks, changes) {        
        return HttpWrapper.put(`${endpoints.FILESCANDESCRIPTIONTASKS}`, { data: fileScanCategoryTasks, changes} );              
    },
    // saveFileScanDescription (fileScanDescription, changes) {        
    //     return HttpWrapper.put(`${endpoints.FILESCANDESCRIPTIONS}`, { data: fileScanDescription, changes} );              
    // },
    deleteFileScanCategoryTasks (ids) {              
        return HttpWrapper.post(`${endpoints.FILESCANDESCRIPTIONTASKS}/delete`, ids);
    },   
};