import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getApproval(moneyMovementApprovalID){
        return HttpWrapper.get(`${endpoints.MONEYMOVEMENTAPPROVALS}/${moneyMovementApprovalID}`, null);
    },
    getPendingApprovals( bankCompanyID, regionID, branchID, moneyMovementTypeID){
        return HttpWrapper.get(`${endpoints.MONEYMOVEMENTAPPROVALS}/${bankCompanyID}/${regionID}/${branchID}/${moneyMovementTypeID}`, null);
    },
    submitMoneyMovementApproval(usersID, approving, item){
        return HttpWrapper.post(`${endpoints.MONEYMOVEMENTAPPROVALS}/${usersID}/${approving}`, item);
    },
};
