import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getToken (credentials) {
        return HttpWrapper.get(`${endpoints.TOKEN}`, { headers: { Authorization: 'Basic ' + btoa(credentials.username + ':' + credentials.password) } });
    },
    setSystem (systemId) {
        return HttpWrapper.put(`${endpoints.TOKEN}/${systemId}`);
    },
}