import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getWorkflowTaskPackages(){
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKPACKAGES}`);
    },
    getAssociatedTasks(wftPackageID){
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKPACKAGES}/${wftPackageID}`, wftPackageID);
    },
    saveWorkflowTaskPackage (wftPackage) {
        return HttpWrapper.put(`${endpoints.WORKFLOWTASKPACKAGES}`, wftPackage );
    },
    deleteWorkflowTaskPackage (wftPackageIDs) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKPACKAGES}/delete`, wftPackageIDs);
    },
    toggleIsInactive(wftPackageIDs) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKPACKAGES}/toggleisinactive`, wftPackageIDs);
    },

};