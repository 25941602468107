import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getNotes (orderRateCalculationId) {return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderRateCalculationId}/notes`);},
    saveNote (note) {
        return HttpWrapper.post(`${endpoints.RATE_ENGINE}/note`, note);
    },
    deleteNote (ids) {
        return HttpWrapper.post(`${endpoints.RATE_ENGINE}/deleteNote`, ids);
    },
    // getNote (orderRateCalculationId, orderRateNoteId) {return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderRateCalculationId}/notes/${orderRateNoteId}`);},
    // addNote (orderRateCalculationId, note) {
    //     return HttpWrapper.post(`${endpoints.RATE_ENGINE}/${orderRateCalculationId}/notes`, note);
    // },
    // updateNote (orderRateCalculationId, note) {
    //     return HttpWrapper.put(`${endpoints.RATE_ENGINE}/${orderRateCalculationId}/notes`, note);
    // },
    // deleteNote(orderRateCalculationId, orderRateNoteId){
    //     return HttpWrapper.delete(`${endpoints.RATE_ENGINE}/${orderRateCalculationId}/notes/${orderRateNoteId}`);
    // }
};