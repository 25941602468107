import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getEpicorValidPayees(){
        return HttpWrapper.get(`${endpoints.EPICORVALIDPAYEES}`);
    },
    saveEpicorValidPayee (epicorValidPayee, changes) {        
        return HttpWrapper.put(`${endpoints.EPICORVALIDPAYEES}`, { data: epicorValidPayee, changes} );              
    },
    deleteEpicorValidPayee (epicorValidPayeeID) {              
        return HttpWrapper.delete(`${endpoints.EPICORVALIDPAYEES}/${epicorValidPayeeID}`);              
    },
};
