import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    convertToSfdt(request) {
        return HttpWrapper.post(`${endpoints.DOCUMENT_SERVICE}/convert/`, request, null);
    },

    testConvertSfdt(request) {
        return HttpWrapper.get(`${endpoints.DOCUMENT_SERVICE}/test-convert-sfdt/`, null);
    },

    openDirectConversion() {
        return HttpWrapper.get(`${endpoints.DOCUMENT_SERVICE}/open-direct-conversion/`, null);
    }
};
