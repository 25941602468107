import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getDocumentSecurityUsers(documentId){        
        return HttpWrapper.get(`${endpoints.DOCUMENTSECURITYUSERS}/${documentId}`);
    },   
    saveDocumentSecurityUsers(documentSecurityUsers, changes = null) {
        return HttpWrapper.put(`${endpoints.DOCUMENTSECURITYUSERS}`, { data: documentSecurityUsers, changes} );              
    },   
};
