import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getRateOrderInfo(orderId) { return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/rateOrderInfo`, null); },
    saveRateOrderInfo(rateOrderInfo) { return HttpWrapper.post(`${endpoints.RATE_ENGINE}/rateOrderInfo`, rateOrderInfo, null); },
    getPremiums(orderId, loanId) {
        return _.gt(loanId, 0)
            ? HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/premiums/${loanId}`, null)
            : HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/premiums`, null);
    },
    getPremiumNames(orderId, loanId) {
        return _.gt(loanId, 0)
            ? HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/premiumOptions/${loanId}`, null)
            : HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/premiumOptions`, null);
    },
    getRate(rateId) { return HttpWrapper.get(`${endpoints.RATE_ENGINE}/rates/${rateId}`, null); },
    getOrderRateCalculations(orderId) { return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/orderratecalculations`, null); },
    getRates(orderId) { return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}`, null); },
    calculate(data) { return HttpWrapper.post(`${endpoints.RATE_ENGINE}/calculate`, data, null); },
    resetPremium(orderID, orderRateCalculationID, simultaneousOrderRateCalculationIDs) {
        return HttpWrapper.post(`${endpoints.RATE_ENGINE}/reset`, { orderID, orderRateCalculationID, simultaneousOrderRateCalculationIDs} );
    },

    //Outside Parties
    addOutsideParty(outsideParty) {return HttpWrapper.post(`${endpoints.RATE_ENGINE}/orderOutsidePartyToPay`, outsideParty, null); },
    deleteOutsideParty (id) { return HttpWrapper.delete(`${endpoints.RATE_ENGINE}/${id}/orderOutsidePartyToPay`); },
    deleteOutsideParties(ids) { return HttpWrapper.post(`${endpoints.RATE_ENGINE}/orderOutsidePartyToPay/delete`, ids); },
    getOutsideParties(orderId) {return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/outsidePartyToPay`, null); },
    saveOutsideParties(outsideParty) {return HttpWrapper.put(`${endpoints.RATE_ENGINE}/orderOutsidePartyToPay`, outsideParty, null); },

    //Endorsements
    getEndorsements(orderId, loanId) { return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/endorsements/${loanId}`, null); },
    addOrderEndorsement(endorsementId) { return HttpWrapper.put(`${endpoints.RATE_ENGINE}/orderendorsements/${endorsementId}`, null); },
    deleteOrderEndorsement(id) { return HttpWrapper.delete(`${endpoints.RATE_ENGINE}/orderendorsements/${id}`, null); },
    refreshAlwaysApplyEndorsements(orderId,orderRateCalculationIDs) { return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/refreshAlwaysApplyEndorsements?orderRateCalculationIDs=${orderRateCalculationIDs.join('&orderRateCalculationIDs=')}`, null); },
};
