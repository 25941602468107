import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    activate (ids) { 
        return HttpWrapper.post(`${endpoints.RECORDINGFEECONFIGURATION}/activate`, ids); 
    },
    getList(){ 
        return HttpWrapper.get(`${endpoints.RECORDINGFEECONFIGURATION}`); 
    },
    
    delete (ids) {              
        return HttpWrapper.post(`${endpoints.RECORDINGFEECONFIGURATION}/delete`, ids);
    },
    
    save (passedData, changes) {        
        return HttpWrapper.post(`${endpoints.RECORDINGFEECONFIGURATION}`, { data: passedData, changes} );              
    },
    
    copy(item) { 
        return HttpWrapper.post(`${endpoints.RECORDINGFEECONFIGURATION}/copy/`, item, null); 
    },

    get (id) { 
        return HttpWrapper.get(`${endpoints.RECORDINGFEECONFIGURATION}/${id}`); 
    },
};
