import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getLossAdvanceReasons(){
        return HttpWrapper.get(`${endpoints.LOSSADVANCEREASONS}`);
    },
    saveLossAdvanceReason(lossAdvanceReason, changes) {
        return HttpWrapper.put(`${endpoints.LOSSADVANCEREASONS}`, { data: lossAdvanceReason, changes} );              
    },
    deleteLossAdvanceReasons(ids) {
        return HttpWrapper.post(`${endpoints.LOSSADVANCEREASONS}/delete`, ids);
    },
    activateLossAdvanceReasons(lossAdvanceReasonIDs) {
        return HttpWrapper.post(`${endpoints.LOSSADVANCEREASONS}/activateLossAdvanceReasons`, lossAdvanceReasonIDs);
    },
};