import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getDeliveryMethods(){
        return HttpWrapper.get(`${endpoints.DELIVERY_METHODS}`);
    },
    saveDeliveryMethod (deliveryMethod, changes) {        
        return HttpWrapper.put(`${endpoints.DELIVERY_METHODS}`, { data: deliveryMethod, changes} );              
    },
    deleteDeliveryMethod (workflowServiceRequestDeliveryMethodID) {              
        return HttpWrapper.delete(`${endpoints.DELIVERY_METHODS}/${workflowServiceRequestDeliveryMethodID}`);              
    },
    toggleActivate(ids) {
        return HttpWrapper.post(`${endpoints.DELIVERY_METHODS}/activate`, ids);
    },
};
