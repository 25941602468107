import { HttpWrapper } from '../shared/services/rest.service';
import { STANDARDLANGUAGE } from './endpoints';

export default {

    get(){
        return HttpWrapper.get(`${STANDARDLANGUAGE}`);
    },
    getContent(id){
        return HttpWrapper.get(`${STANDARDLANGUAGE}/content/${id}`);
    },
    getDocxContent(id){
        return HttpWrapper.get(`${STANDARDLANGUAGE}/content/${id}/docx`);
    },
    getByCategoryId(categoryId){
        return HttpWrapper.get(`${STANDARDLANGUAGE}/${categoryId}`);
    },
    save (data, changes) {
        return HttpWrapper.post(`${STANDARDLANGUAGE}`, { data, changes}, null);
    },
    saveContent(id, content){
        return HttpWrapper.post(`${STANDARDLANGUAGE}/content/${id}`, { data: content }, null);
    },
    delete(ids) {
        return HttpWrapper.post(`${STANDARDLANGUAGE}/delete`, ids, null);
    },
    exportClauses(zip, ids) {
        return HttpWrapper.post(`${STANDARDLANGUAGE}/export/${zip}`, ids, null);
    },
    getPackages() {
        return HttpWrapper.get(`${STANDARDLANGUAGE}/packages`);
    },
    savePackage(data) {
        return HttpWrapper.post(`${STANDARDLANGUAGE}/packages`, data, null);
    },
    deletePackages(ids) {
        return HttpWrapper.delete(`${STANDARDLANGUAGE}/packages/${_.join(ids, ",")}`, null);
    },
    getSections() {
        return HttpWrapper.get(`${STANDARDLANGUAGE}/sections`);
    },
    saveSection(data) {
        return HttpWrapper.post(`${STANDARDLANGUAGE}/section`, data, null);
    },
};
