import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getRecordingDocuments(){
        return HttpWrapper.get(`${endpoints.RECORDING_DOCUMENTS}`);
    },
    getRecordingDocumentLineTypes(){
        return HttpWrapper.get(`${endpoints.RECORDING_DOCUMENTS}/lineTypes`);
    },
    saveRecordingDocument (fileScanDescription, changes) {        
        return HttpWrapper.put(`${endpoints.RECORDING_DOCUMENTS}`, { data: fileScanDescription, changes} );              
    },
    deleteRecordingDocuments (ids) {              
        return HttpWrapper.post(`${endpoints.RECORDING_DOCUMENTS}/delete`, ids);
    },
};