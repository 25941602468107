import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getCustomDataValues(referenceTablePkValue, referenceTable, customDataTabID=0){
        return HttpWrapper.get(`${endpoints.ORDER_CUSTOM_DATA}/customDataValues/${referenceTablePkValue}/${referenceTable}/${customDataTabID}`);        
    },

    getCustomDataListValues(customDataDefinitionID){
        return HttpWrapper.get(`${endpoints.ORDER_CUSTOM_DATA}/customDataListValues/${customDataDefinitionID}`);        
    },

    saveCustomData(customData) {
        return HttpWrapper.post(`${endpoints.ORDER_CUSTOM_DATA}/customData`, customData);
    },

    getCustomDataCompanyContact(companyID, contactID) {
        return HttpWrapper.get(`${endpoints.ORDER_CUSTOM_DATA}/companyContact/${companyID}/${contactID}`);
    },

    getCustomDataTabs(referenceTable, regionID){
        return HttpWrapper.get(`${endpoints.ORDER_CUSTOM_DATA}/custom/${referenceTable}/${regionID}`);
    },

    getTabs(referenceTable){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/tabs/${referenceTable}`);        
    },
    
    saveTab(customDataTab, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/tab`, { data: customDataTab, changes} );              
    },

    saveTabs(customDataTab, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/tabs`, { data: customDataTab, changes} );              
    },

    deleteTabs (ids) {
        return HttpWrapper.post(`${endpoints.CUSTOM_DATA}/tabs/deleteTabs`, ids);
    },

    getDefinitionsByReferenceTable(referenceTable){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/definitions/${referenceTable}`);        
    },

    getDefinitionsByCustomDataTabID(CustomDataTabID){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/tab/${CustomDataTabID}/definitions/`);        
    },

    saveDefinition(customDataDefinition, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/definition`, { data: customDataDefinition, changes} );              
    },

    saveDefinitions(customDataDefinition, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/definitions`, { data: customDataDefinition, changes} );              
    },

    deleteDefinitions (ids) {
        return HttpWrapper.post(`${endpoints.CUSTOM_DATA}/definitions/deleteDefinitions`, ids);
    },

    getListValues(customDataDefinitionID){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/definition/${customDataDefinitionID}/listvalue/`);        
    },

    saveListValue(customDataListValue, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/listvalue`, { data: customDataListValue, changes} );              
    },

    saveListValues(customDataListValues, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/listvalues`, { data: customDataListValues, changes} );              
    },

    deleteListValues (ids) {
        return HttpWrapper.post(`${endpoints.CUSTOM_DATA}/listvalues/delete`, ids);
    },

    getDefinitionParents(){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/definition/parents`);        
    },

    getListValueParents(){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/listvalue/parents`);        
    },

    getListValueByParentID(parentID){
        return HttpWrapper.get(`${endpoints.CUSTOM_DATA}/listvalues/${parentID}`);        
    },

    updateListValuesParentID(customDataListValue, changes = null) {
        return HttpWrapper.put(`${endpoints.CUSTOM_DATA}/listvalues/parent`, { data: customDataListValue, changes} );     
    },
};
