import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getExtensions: () => {
        return HttpWrapper.get(`${endpoints.SYSTEMS}/systemextensions`, null, false);
    },
    getCurrent: () => {
        return HttpWrapper.get(`${endpoints.SYSTEMS}/current`, null, false);
    }

};
