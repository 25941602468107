import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default{
    getAlertTotals(){ return HttpWrapper.get(`${endpoints.INTERNETALERTS}/alerttotals`); },
    toggleRead(ids){ return HttpWrapper.post(`${endpoints.INTERNETALERTS}/toggleread`, ids);},
    searchAlerts(searchRequest){ return HttpWrapper.post(`${endpoints.INTERNETALERTS}/search`, searchRequest);},
    getAlertDataMapping(id){ return HttpWrapper.get(`${endpoints.INTERNETALERTS}/alerts/${id}`); },
    applyAlertDataMapping(mapping){ return HttpWrapper.post(`${endpoints.INTERNETALERTS}/apply`, mapping);},
    getFileAlertTotals(id){ return HttpWrapper.get(`${endpoints.INTERNETALERTS}/alerttotals/${id}`); },
    searchFileAlerts(searchRequest){ return HttpWrapper.post(`${endpoints.INTERNETALERTS}/filesearch`, searchRequest);},
}