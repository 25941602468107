import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';
//RQO-32635
// import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";

// const SmartyStreetsCore = SmartyStreetsSDK.core;
// const websiteKey = appSettings.smartyStreetsWebKey;
// const smartyStreetsSharedCredentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
// const autoCompleteClientBuilder = new SmartyStreetsCore.ClientBuilder(smartyStreetsSharedCredentials);
// const autoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient();

export default {
    getProperties (orderId) {
        return HttpWrapper.get(`/orders/${orderId}/properties/`);
    },

    addProperty (propertyDto) {
        return HttpWrapper.post(endpoints.PROPERTIES, propertyDto);
    },

    updateProperty (propertyDto, changes) {
        return HttpWrapper.put(`${endpoints.PROPERTIES}`, { data: propertyDto, changes});
    },

    deleteProperty (orderId, propertyIds) {
        return HttpWrapper.post(`${endpoints.PROPERTIES}/${orderId}/Delete`, propertyIds);
    },

    getBriefLegalDescription (property) {
        return HttpWrapper.post(`${endpoints.PROPERTIES}/briefLegalDesc`, property, null);
    },

    getOrderFirstPropertyState (orderId) {
        return HttpWrapper.get(`/orders/${orderId}/properties/state/`);
    },

    getStreetViewMetadata(address) {
        return HttpWrapper.get(`${endpoints.PROPERTIES}/propertymetadata?address=${address}`);
    },

    getStreetViewImage(address, size) {
        return HttpWrapper.get(`${endpoints.PROPERTIES}/propertyimage?address=${address}&size=${size}`);
    }
};
