import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getEpicorUnderwriterSplitRates(){
        return HttpWrapper.get(`${endpoints.EPICORUNDERWRITERSPLITRATES}`);
    },
    saveEpicorUnderwriterSplitRate (epicorUnderwriterSplitRate, changes) {        
        return HttpWrapper.put(`${endpoints.EPICORUNDERWRITERSPLITRATES}`, { data: epicorUnderwriterSplitRate, changes} );              
    },
    deleteEpicorUnderwriterSplitRate (epicorUnderwriterSplitRateID) {              
        return HttpWrapper.delete(`${endpoints.EPICORUNDERWRITERSPLITRATES}/${epicorUnderwriterSplitRateID}`);              
    },
};
