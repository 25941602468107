import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {
    get(){
        return HttpWrapper.get(`${endpoints.STANDARDLANGUAGECATEGORY}`);
    },
    delete(ids) {
        return HttpWrapper.post(`${endpoints.STANDARDLANGUAGECATEGORY}/delete`, ids, null);
    },
    exportClauses(id, zip){
        return HttpWrapper.get(`${endpoints.STANDARDLANGUAGECATEGORY}/export/${id}/${zip}`);
    },
    save (data) {
        return HttpWrapper.post(endpoints.STANDARDLANGUAGECATEGORY, data, null);
    },
    importClauses (formRequest) {
        return HttpWrapper.put(`${endpoints.STANDARDLANGUAGECATEGORY}/upload/`, formRequest, null);
    },
    getSections(id=0){
        return id > 0
            ? HttpWrapper.get(`${endpoints.STANDARDLANGUAGECATEGORY}/sections/${id}`)
            : HttpWrapper.get(`${endpoints.STANDARDLANGUAGECATEGORY}/sections`);
    },
    deleteSections(ids) {
        return HttpWrapper.post(`${endpoints.STANDARDLANGUAGECATEGORY}/deleteSections`, ids, null);
    },
    saveSection (data) {
        return HttpWrapper.post(`${endpoints.STANDARDLANGUAGECATEGORY}/section`, data, null);
    },

    getSecurityUsers(id){
        return HttpWrapper.get(`${endpoints.STANDARDLANGUAGECATEGORY}/users/${id}`);
    },
    saveSecurityUsers(id, data) {
        return HttpWrapper.put(`${endpoints.STANDARDLANGUAGECATEGORY}/users/${id}`, data, null);
    },
};