import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getTaxRates(){
        return HttpWrapper.get(`${endpoints.TAXRATES}`);
    },   
    saveTaxRate (taxRate, changes) {        
        return HttpWrapper.put(`${endpoints.TAXRATES}`, { data: taxRate, changes} );              
    },  
    deleteTaxRates (ids) {
        return HttpWrapper.post(`${endpoints.TAXRATES}/delete`, ids);
    }
};