import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    deleteOrderTimeTracking (ids) {
        return HttpWrapper.post(`${endpoints.ORDER_TIME_TRACKING}/deleteTimeTracking`, ids);
    },
    deleteOrderTimeTrackingLossDetail (ids) {
        return HttpWrapper.post(`${endpoints.ORDER_TIME_TRACKING}/deleteLossDetail`, ids);
    },
    getOrderTimeTrackingDataByOrdersID(ordersID){
        return HttpWrapper.get(`${endpoints.ORDER_TIME_TRACKING}/${ordersID}/data`);
    },
    getOrderTimeTrackingByOrdersID(ordersID){
        return HttpWrapper.get(`${endpoints.ORDER_TIME_TRACKING}/${ordersID}/timeTracking`);
    },
    getOrderTimeTrackingLossInfoByOrdersID(ordersID){
        return HttpWrapper.get(`${endpoints.ORDER_TIME_TRACKING}/${ordersID}/lossInfo`);
    },
    getOrderTimeTrackingLossDetailByOrdersID(ordersID){
        return HttpWrapper.get(`${endpoints.ORDER_TIME_TRACKING}/${ordersID}/lossDetail`);
    },
    saveOrderTimeTracking(orderTimeTracking, changes = null){
        return HttpWrapper.put(`${endpoints.ORDER_TIME_TRACKING}/timeTracking`, { data: orderTimeTracking, changes});
    },
    saveOrderTimeTrackingLossDetail(orderTimeTrackingLossDetail, changes = null){
        return HttpWrapper.put(`${endpoints.ORDER_TIME_TRACKING}/lossDetail`, { data: orderTimeTrackingLossDetail, changes});
    },
    saveOrderTimeTrackingLossInfo(orderTimeTrackingLossInfo, changes = null){
        return HttpWrapper.put(`${endpoints.ORDER_TIME_TRACKING}/lossInfo`, { data: orderTimeTrackingLossInfo, changes});
    },
};