import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getRegions () {return HttpWrapper.get(`${endpoints.LOOKUPS}/regions`);},
    getBranchesByRegion (regionId) {return HttpWrapper.get(`${endpoints.LOOKUPS}/regions/${regionId}/branches`, null);},
    getSubdivisionsByRegion (regionId) {return HttpWrapper.get(`${endpoints.LOOKUPS}/regions/${regionId}/subdivisions`);},
    getStates () {return HttpWrapper.get(`${endpoints.LOOKUPS}/states`);},
    getCounties () {return HttpWrapper.get(`${endpoints.LOOKUPS}/counties`);},
    getCountiesByState (state) {return HttpWrapper.get(`${endpoints.LOOKUPS}/states/${state}/counties`);},
    getSystemLookup (name, regionId) {return HttpWrapper.get(`${endpoints.LOOKUPS}/byname/${name}/${regionId}`);},
    getCustomLookup (name, regionId) {return HttpWrapper.get(`${endpoints.LOOKUPS}/byname/custom/${name}/${regionId}`);},
    getProrationDefaults () {return HttpWrapper.get(`${endpoints.LOOKUPS}/prorationdefaults`);},
    getCustomers () {return HttpWrapper.get(`${endpoints.LOOKUPS}/customers`);},
    getBuyersSellers () {return HttpWrapper.get(`${endpoints.LOOKUPS}/buyersSellers`);},
    getCompanyContacts () {return HttpWrapper.get(`${endpoints.LOOKUPS}/companyContacts`);},
};
