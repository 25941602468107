import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    activateNotifications(notificationEventIDs) {
        return HttpWrapper.post(`${endpoints.NOTIFICATION}/activateNotifications`, notificationEventIDs);
    },
    getNotifications(){
        return HttpWrapper.get(`${endpoints.NOTIFICATION}`);
    },
    saveNotification (emailNotification, changes) {        
        return HttpWrapper.put(`${endpoints.NOTIFICATION}/`, { data: emailNotification, changes} );              
    },
    getMergeFields(){
        return HttpWrapper.get(`${endpoints.NOTIFICATION}/mergeFields`);
    }
};
