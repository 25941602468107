import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getAccountingCodes(){
        return HttpWrapper.get(`${endpoints.ACCOUNTINGCODES}`);
    },
    saveAccountingCode(accountingCode, changes = null) {
        return HttpWrapper.put(`${endpoints.ACCOUNTINGCODES}`, { data: accountingCode, changes} );              
    },
    deleteAccountingCode (AccountingCodeId) {              
        return HttpWrapper.delete(`${endpoints.ACCOUNTINGCODES}/${AccountingCodeId}`);              
    },
    deleteAccountingCodes (ids) {
        return HttpWrapper.post(`${endpoints.ACCOUNTINGCODES}/delete`, ids);
    },
    toggleIsInactive(accountingCodeIds) {
        return HttpWrapper.post(`${endpoints.ACCOUNTINGCODES}/toggleisinactive`, accountingCodeIds);
    },
};
