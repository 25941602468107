import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getTypeFunds(){
        return HttpWrapper.get(`${endpoints.TYPEFUNDS}`);
    },
    saveTypeFund (typeFund, changes) {        
        return HttpWrapper.put(`${endpoints.TYPEFUNDS}`, { data: typeFund, changes} );              
    },
    deleteTypeFund (typeFundID) {              
        return HttpWrapper.delete(`${endpoints.TYPEFUNDS}/${typeFundID}`);              
    },
    toggleIsInactive(staffIds) {
        return HttpWrapper.post(`${endpoints.TYPEFUNDS}/toggleisinactive`, staffIds);
    },
};
