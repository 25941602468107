import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getDocumentPackageSecurityUsers(packageId){        
        return HttpWrapper.get(`${endpoints.DOCUMENTPACKAGESECURITYUSERS}/${packageId}`);
    },   
    saveDocumentPackageSecurityUsers(documentPackageSecurityUsers, changes = null) {
        return HttpWrapper.put(`${endpoints.DOCUMENTPACKAGESECURITYUSERS}`, { data: documentPackageSecurityUsers, changes} );              
    },   
};
