import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    get() {
        return HttpWrapper.get(`${endpoints.TITLE_PROD_CUSTOM_TABS}/`);
    },
    getTabs() {
        return HttpWrapper.get(`${endpoints.TITLE_PROD_CUSTOM_TABS}/tabs`);
    },
    save(data) {
        return HttpWrapper.post(`${endpoints.TITLE_PROD_CUSTOM_TABS}`, data);
    },
    saveSection(standardLanguageSection, changes) {
        return HttpWrapper.put(`${endpoints.TITLE_PROD_CUSTOM_TABS}/section`, { data: standardLanguageSection, changes} );
    },
    reorder(data) {
        return HttpWrapper.post(`${endpoints.TITLE_PROD_CUSTOM_TABS}/reorder`, data);
    },
    reorderSections(data) {
        return HttpWrapper.post(`${endpoints.TITLE_PROD_CUSTOM_TABS}/sections/reorder`, data);
    },
    delete(ids) {
        return HttpWrapper.post(`${endpoints.TITLE_PROD_CUSTOM_TABS}/delete`, ids);
    },
    deleteSections (ids) {
        return HttpWrapper.post(`${endpoints.TITLE_PROD_CUSTOM_TABS}/sections/delete`, ids);
    },
};
