import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getPropertyTypes(){
        return HttpWrapper.get(`${endpoints.PROPERTYTYPES}`);
    },
    savePropertyType(propertyType, changes = null) {
        return HttpWrapper.put(`${endpoints.PROPERTYTYPES}`, { data: propertyType, changes} );              
    },
    deletePropertyType(propertyTypeId) {              
        return HttpWrapper.delete(`${endpoints.PROPERTYTYPES}/${propertyTypeId}`);              
    },
    toggleIsInactive(propertyTypeIds) {
        return HttpWrapper.post(`${endpoints.PROPERTYTYPES}/toggleisinactive`, propertyTypeIds);
    },
};

