import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    addressLookup(address, state) {
        return HttpWrapper.get(`${endpoints.COMPANY_ADDRESSES}/addressLookup?address=${address}&state=${state}`);
    },
    getcompanyAddresses(companyID) {
        return HttpWrapper.get(`${endpoints.COMPANY_ADDRESSES}/company/${companyID}`);
    },
    saveCompanyAddress(companyAddress, changes) {        
        return HttpWrapper.put(`${endpoints.COMPANY_ADDRESSES}`, { data: companyAddress, changes} );              
    },
    deleteCompanyAddress(companyAddressID) {
        return HttpWrapper.delete(`${endpoints.COMPANY_ADDRESSES}/${companyAddressID}`);              
    },
    deleteCompanyAddresses(companyAddressIDs) {
        return HttpWrapper.post(`${endpoints.COMPANY_ADDRESSES}/delete`, companyAddressIDs);
    },
    verifyCompanyAddress(verifyAddressRequest) {
        return HttpWrapper.post(`${endpoints.COMPANY_ADDRESSES}/verifyAddress`, verifyAddressRequest);
    }
};