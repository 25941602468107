import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';
import Store from '../store/';

//const getDocApiUrl = () => "";

export default {

    applyTemplate: data => HttpWrapper.put(`${endpoints.COMMITMENTS}/applytemplate`, data),

    getCommitmentDetailList: (commitmentPolicyHeaderId) => HttpWrapper.get(`${endpoints.COMMITMENTS}/commitmentdetail/${commitmentPolicyHeaderId}`, null),

    getData: (orderId, loanId) => HttpWrapper.get(`${endpoints.COMMITMENTS}/${orderId}/loan/${loanId}`, null),

    getTitleProductionData: (orderId, loanId) => HttpWrapper.get(`${endpoints.COMMITMENTS}/title/${orderId}/loan/${loanId}`, null),

    updateData: data => HttpWrapper.put(`${endpoints.COMMITMENTS}`, data, null),

    updatePolicyOptions: data => HttpWrapper.put(`${endpoints.COMMITMENTS}/policyoptions`, data, null),

    voidPolicy: data => HttpWrapper.put(`${endpoints.COMMITMENTS}/voidpolicy`, data),

    getVoidedPolicies: orderId => HttpWrapper.get(`${endpoints.COMMITMENTS}/voidpolicy/${orderId}`, null),

    getPolicyList: orderId => HttpWrapper.get(`${endpoints.COMMITMENTS}/policyList/${orderId}`, null),
    // getScheduleD: orderId => HttpWrapper.get(`${endpoints.COMMITMENTS}/scheduled/${orderId}`, null),

    getAssignedLanguagesByDetail: (commitmentPolicyDetailId, listType=null, sectionId=null) => {
        if(_.isNil(listType) && _.isNil(sectionId))
            return HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/assignedlanguages/`, null);

        return _.isNil(sectionId)
            ? HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/assignedlanguages/${listType}`, null)
            : HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/assignedlanguages/custom/${sectionId}`, null);
    },

    getAssignedLanguagesByHeader: (commitmentPolicyHeaderId, listType=null, sectionId=null) => {
        if(_.isNil(listType) && _.isNil(sectionId))
            return HttpWrapper.get(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/assignedlanguages/`, null);

        return _.isNil(sectionId)
            ? HttpWrapper.get(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/assignedlanguages/${listType}`, null)
            : HttpWrapper.get(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/assignedlanguages/custom/${sectionId}`, null);
    },

    swapAssignedLanguageOrdinals: (assignedLanguageId1, assignedLanguageId2) => HttpWrapper.put(`${endpoints.COMMITMENTS}/assignedlanguages/swapordinal/${assignedLanguageId1}/${assignedLanguageId2}`, null),

    refreshAssignedLanguageListStructure: (commitmentPolicyHeaderId=null, commitmentPolicyDetailId=null, listType=null, sectionId=null) => {
        let listTypeVal = listType || "";
        if(!_.isNil(commitmentPolicyHeaderId)) {
            return _.isNil(sectionId)
                ? HttpWrapper.get(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/assignedlanguages/refreshlist/${listTypeVal}`, null)
                : HttpWrapper.get(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/assignedlanguages/refreshlist/custom/${sectionId}`, null);
        }
        else if(!_.isNil(commitmentPolicyDetailId)) {
            return _.isNil(sectionId)
                ? HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/assignedlanguages/refreshlist/${listTypeVal}`, null)
                : HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/assignedlanguages/refreshlist/custom/${sectionId}`, null);
        }
    },

    setAssignedLanguageParent: (assignedLanguageId, parentId) => _.isNil(parentId)
        ? HttpWrapper.put(`${endpoints.COMMITMENTS}/assignedlanguages/setparent/${assignedLanguageId}`, null, null)
        : HttpWrapper.put(`${endpoints.COMMITMENTS}/assignedlanguages/setparent/${assignedLanguageId}/${parentId}`, null, null),

    addAssignedLanguagePackage: pkg => HttpWrapper.post(`${endpoints.COMMITMENTS}/assignedlanguage/package`, pkg, null),

    mergeAssignedLanguagePackageRange: pkgs => HttpWrapper.post(`${endpoints.COMMITMENTS}/assignedlanguage/packages/merge`, pkgs, null),

    saveAssignedLanguage: assignedLanguage => HttpWrapper.post(`${endpoints.COMMITMENTS}/assignedlanguage/`, assignedLanguage, null),

    saveAssignedLanguageRange: assignedLanguages => HttpWrapper.post(`${endpoints.COMMITMENTS}/assignedlanguages/`, assignedLanguages, null),

    mergeAssignedLanguageRange: assignedLanguages => HttpWrapper.post(`${endpoints.COMMITMENTS}/assignedlanguages/merge`, assignedLanguages, null),

    deleteAssignedLanguage: assignedLanguageID => HttpWrapper.delete(`${endpoints.COMMITMENTS}/assignedlanguage/${assignedLanguageID}`, null),

    deleteAssignedLanguagesByListType: (commitmentPolicyHeaderId=null, commitmentPolicyDetailId=null, listType=null) => {
        if(_.isNil(listType)){
            console.error("CommitmentsApi.deleteAssignedLanguageByListType :: Invalid ListType Value");
            return;
        }
        if(!_.isNil(commitmentPolicyHeaderId))
            return HttpWrapper.delete(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/listType/${listType}/assignedlanguages`, null);
        else if(!_.isNil(commitmentPolicyDetailId))
            return HttpWrapper.delete(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/listType/${listType}/assignedlanguages`, null);
    },

    deleteAssignedLanguagesBySectionId: (commitmentPolicyDetailId=null, sectionId=null) => {
        if(_.isNil(sectionId)){
            console.error("CommitmentsApi.deleteAssignedLanguageBySectionId :: Invalid SectionId Value");
            return;
        }
        if(!_.isNil(commitmentPolicyDetailId))
            return HttpWrapper.delete(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/section/${sectionId}/assignedlanguages`, null);
    },

    removeOwnerPolicyClauseOverride: commitmentPolicyHeaderId => HttpWrapper.get(`${endpoints.COMMITMENTS}/header/${commitmentPolicyHeaderId}/assignedlanguages/removepolicyoverride`, null),

    removeLoanPolicyClauseOverride: commitmentPolicyDetailId => HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${commitmentPolicyDetailId}/assignedlanguages/removepolicyoverride`, null),

    uploadCommmitmentData: formRequest => HttpWrapper.put(`${endpoints.COMMITMENTS}/upload`, formRequest, null),

    downloadCommmitmentData: (orderId, loanId, fileName) => HttpWrapper.post(`${endpoints.COMMITMENTS}/download`, { OrderID: orderId, LoanID: loanId, FileName: fileName }, null),

    getPolicyNumberRanges: (regionId, underwriterId, policyType) => HttpWrapper.get(`${endpoints.COMMITMENTS}/policyNumbers/${regionId}/${underwriterId}/${policyType}`, null),

    updatePolicyNumber: data => HttpWrapper.put(`${endpoints.COMMITMENTS}/policyNumber`, data, null),

    getHistory: (orderId, searchCriteria) => HttpWrapper.post(`${endpoints.COMMITMENTS}/history/${orderId}`, searchCriteria, null),

    clearPolicyNumbers: (orderId) => HttpWrapper.put(`${endpoints.COMMITMENTS}/clearPolicyNumbers/${orderId}`),

    createHistoryLabel: data => HttpWrapper.post(`${endpoints.COMMITMENTS}/history/label`, data, null),

    getCustomTabs: () => HttpWrapper.get(`${endpoints.COMMITMENTS}/customtabs`, null),

    getScheduleD: orderId => HttpWrapper.get(`${endpoints.COMMITMENTS}/scheduled/${orderId}`, null),

    getStandardLanguageSections: () => HttpWrapper.get(`${endpoints.COMMITMENTS}/sections`, null),

    getUsersWithSignatures: () => HttpWrapper.get(`${endpoints.COMMITMENTS}/signatories`, null),

    appendLegalDescription: (detailId, orderId, content) => HttpWrapper.post(`${endpoints.COMMITMENTS}/detail/${detailId}/legal-description/${orderId}`, { content }),

    appendBriefLegalDescription: (orderId, content) => HttpWrapper.post(`${endpoints.COMMITMENTS}/legal-description/${orderId}`, { content }),
    getAssignedLanguageCopies: (detailId, listType, orderId) => HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${detailId}/list-type/${listType}/copy-from-file/${orderId}`, null),

    getCustomAssignedLanguageCopies: (detailId, sectionId, orderId) => HttpWrapper.get(`${endpoints.COMMITMENTS}/detail/${detailId}/section/${sectionId}/copy-from-file/${orderId}`, null)
 };