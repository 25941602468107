import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getMoneyMovementApprovalRanges(){
        return HttpWrapper.get(`${endpoints.MONEYMOVEMENTAPPROVALRANGES}`);
    },
    saveMoneyMovementApprovalRange (approvalRange, changes) {
        return HttpWrapper.put(`${endpoints.MONEYMOVEMENTAPPROVALRANGES}`, { data: approvalRange, changes} );              
    },
    deleteMoneyMovementApprovalRange (approvalRangeID) {              
        return HttpWrapper.delete(`${endpoints.MONEYMOVEMENTAPPROVALRANGES}/${approvalRangeID}`);              
    },
    deleteMoneyMovementApprovalRanges (ids) {
        return HttpWrapper.post(`${endpoints.MONEYMOVEMENTAPPROVALRANGES}/delete`, ids);
    },
    activateMoneyMovementApprovalRanges(approvalRangeIDs) {
        return HttpWrapper.post(`${endpoints.MONEYMOVEMENTAPPROVALRANGES}/activateApprovalRanges`, approvalRangeIDs);
    },
};
