import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getBuyerSellerRelationships(){
        return HttpWrapper.get(`${endpoints.BUYER_SELLER_RELATIONSHIP}`);
    },
    saveBuyerSellerRelationship (buyerSellerRelationship, changes) {        
        return HttpWrapper.put(`${endpoints.BUYER_SELLER_RELATIONSHIP}`, { data: buyerSellerRelationship, changes} );              
    },
    deleteBuyerSellerRelationship (BuyerSellerRelationshipId) {              
        return HttpWrapper.delete(`${endpoints.BUYER_SELLER_RELATIONSHIP}/${BuyerSellerRelationshipId}`);              
    },
    activateBuyerSellerRelationship(BuyerSellerRelationshipIDs) {
        return HttpWrapper.post(`${endpoints.BUYER_SELLER_RELATIONSHIP}/activateBuyerSellerRelationships`, BuyerSellerRelationshipIDs);
    },
};
