import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    createReconFromImport(bankCompanyID, autoReconUserImportID, finalized) {
        return HttpWrapper.get(`${endpoints.EBANKRECON}/createReconFromImport/${bankCompanyID}/${autoReconUserImportID}/${finalized}`, null);
    },

    deleteImport(bankCompanyID, autoReconUserImportID) {
        return HttpWrapper.get(`${endpoints.EBANKRECON}/deleteImport/${bankCompanyID}/${autoReconUserImportID}`, null);
    },

    getChecks (request) {
        return HttpWrapper.post(`${endpoints.EBANKRECON}/escrowChecks/`, request, null);
    },

    getDeposits (request) {
        return HttpWrapper.post(`${endpoints.EBANKRECON}/escrowDeposits/`, request, null);
    },

    getData(bankCompanyID, autoReconUserImportID){
        return HttpWrapper.get(`${endpoints.EBANKRECON}/${bankCompanyID}/${autoReconUserImportID}`, null);
    },

    getEscrowCheck(checksID){
        return HttpWrapper.get(`${endpoints.EBANKRECON}/escrowCheck/${checksID}`, null);
    },

    getEscrowDeposit(depositID){
        return HttpWrapper.get(`${endpoints.EBANKRECON}/escrowDeposit/${depositID}`, null);
    },

    process(bankCompanyID, fileAsFormData) {
        return HttpWrapper.post(`${endpoints.EBANKRECON}/process/${bankCompanyID}`, fileAsFormData, { headers: { 'Content-Type': 'multipart/form-data' } } );
    },

    deleteTransactionDetail (bankCompanyID, autoReconUserImportID, data, changes = null) {
        return HttpWrapper.post(`${endpoints.EBANKRECON}/deleteTransactionDetail/${bankCompanyID}/${autoReconUserImportID}`, { data: data, changes: changes}, null);
    },

    saveTransactionDetail (bankCompanyID, data, changes = null) {
        return HttpWrapper.post(`${endpoints.EBANKRECON}/saveTransactionDetail/${bankCompanyID}`, { data: data, changes: changes}, null);
    },
};
