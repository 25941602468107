import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getEpicorLocations(){
        return HttpWrapper.get(`${endpoints.EPICORLOCATIONS}`);
    },
    saveEpicorLocation (epicorLocation, changes) {        
        return HttpWrapper.put(`${endpoints.EPICORLOCATIONS}`, { data: epicorLocation, changes} );              
    },
    deleteEpicorLocation (epicorLocationID) {              
        return HttpWrapper.delete(`${endpoints.EPICORLOCATIONS}/${epicorLocationID}`);              
    },
};
