import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getListCodes(){
        return HttpWrapper.get(`${endpoints.LISTCODES}`);
    },
    saveListCode (listCode, changes) {        
        return HttpWrapper.put(`${endpoints.LISTCODES}`, { data: listCode, changes} );              
    },
    deleteListCodes (ids) {              
        //BATCH DELETE ENDPOINT NEEDS TO BE IMPLEMENTED
        return HttpWrapper.post(`${endpoints.LISTCODES}/delete`, ids);
        
    }
};
