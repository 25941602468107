import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    activate (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/activate`, ids);
    },
    get(id){
        return HttpWrapper.get(`${endpoints.RATES}/${id}`);
    },
    getAll(dateValue){
        return HttpWrapper.get(`${endpoints.RATES}/effectiveOn/${dateValue}`);
    },
    create (item) {
        return HttpWrapper.post(`${endpoints.RATES}`, item, null);
    },
    link (item) {
        return HttpWrapper.post(`${endpoints.RATES}/linkRate`, item, null);
    },
    copyRates(item) {
        return HttpWrapper.post(`${endpoints.RATES}/copyRates/`, item, null);
    },
    importRates(items) {
        return HttpWrapper.post(`${endpoints.RATES}/importRates/`, items, null);
    },
    save (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}`, { data: item, changes} );
    },
    delete (rateIDs) {
        return HttpWrapper.post(`${endpoints.RATES}/delete`, rateIDs);
    },    
    saveCoverageRounding (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/coverageRounding`, { data: item, changes} );
    },
    deleteCoverageRounding (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deleteCoverageRounding`, ids);
    },
    saveCoverageRoundingReissue (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/coverageRoundingReissue`, { data: item, changes} );
    },
    deleteCoverageRoundingReissue (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deleteCoverageRoundingReissue`, ids);
    },
    savePremiumRounding (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/premiumRounding`, { data: item, changes} );
    },
    deletePremiumRounding (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deletePremiumRounding`, ids);
    },
    savePremiumRoundingReissue (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/premiumRoundingReissue`, { data: item, changes} );
    },
    deletePremiumRoundingReissue (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deletePremiumRoundingReissue`, ids);
    },
    saveTitleInsuranceRate (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/titleInsuranceRate`, { data: item, changes} );
    },
    deleteTitleInsuranceRate (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deleteTitleInsuranceRate`, ids);
    },
    saveReissueCredit (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/reissueCredit`, { data: item, changes} );
    },
    deleteReissueCredit (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deleteReissueCredit`, ids);
    },
    saveUnderwriterSplit (item, changes) {
        return HttpWrapper.put(`${endpoints.RATES}/underwriterSplit`, { data: item, changes} );
    },
    deleteUnderwriterSplit (ids) {
        return HttpWrapper.post(`${endpoints.RATES}/deleteUnderwriterSplit`, ids);
    },
    searchPremiums (request) {
        return HttpWrapper.post(`${endpoints.RATES}/premiums/`, request, null);
    },
    searchPremium (searchRequest) {
        return HttpWrapper.post(`${endpoints.RATES}/premium/search`, searchRequest);
    },
    unlinkPremium(item) {
        return HttpWrapper.post(`${endpoints.RATES}/unlinkPremium/`, item, null);
    },

};
