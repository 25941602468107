import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getMortgageTypes(){
        return HttpWrapper.get(`${endpoints.MORTGAGE_TYPES}`);
    },
    saveMortgageType(mortgagetype, changes = null) {
        return HttpWrapper.put(`${endpoints.MORTGAGE_TYPES}`, { data: mortgagetype, changes} );              
    },
    deleteMortgageType (mortgageTypeId) {              
        return HttpWrapper.delete(`${endpoints.MORTGAGE_TYPES}/${mortgageTypeId}`);              
    },
    deleteMortgageTypes (ids) {
        return HttpWrapper.post(`${endpoints.MORTGAGE_TYPES}/delete`, ids);
    },
    toggleIsInactive(mortgageTypeIds) {
        return HttpWrapper.post(`${endpoints.MORTGAGE_TYPES}/toggleisinactive`, mortgageTypeIds);
    },
};
