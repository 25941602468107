import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getApiHealth() {
        return HttpWrapper.get(`${endpoints.HEALTH}`);
    },

    getUiHealth() {
        return HttpWrapper.get(`${location.origin}/config/health.json`);
    },

}