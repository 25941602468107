import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    htmlToRtf (content) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/HtmlToRtf`, { content }, null);
    },

    htmlToDocx (content) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/HtmlToDocx`, { content }, null);
    },

    rtfToHtml (content, sanitize=false, fragment=false) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/RtfToHtml`, { content, sanitize, fragment }, null);
    },

    rtfToDocx (content, sanitize=false) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/RtfToDocx`, { content, sanitize }, null);
    },

    docxToRtf(content, sanitize=false) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/DocxToRtf`, { content, sanitize }, null);
    },

    docxToHtml(content, fragment=false) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/DocxToHtml`, { content, fragment }, null);
    },

    docxToPdf(content, restoreCheckboxes=false) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/DocxToPdf`, { content, restoreCheckboxes }, null);
    },

    rtfToSfdt (content) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/RtfToSfdt`, { content }, null);
    },

    batchRtfToSfdt (contentList) {
        let request = _.map(contentList, content => ({ content }));
        return HttpWrapper.post(`${endpoints.UTILITIES}/BatchRtfToSfdt`, request, null);
    },

    sfdtToRtf (content) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/SfdtToRtf`, { content }, null);
    },

    sfdtToHtml (content, fragment=false) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/SfdtToHtml`, { content, fragment }, null);
    },

    sendEmail (email) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/SendEmail/`, email);
    },

    testMicrImage(text) {
        return HttpWrapper.get(`${endpoints.UTILITIES}/micr-image/${encodeURIComponent(text)}`);
    },

    addressAutoComplete(prefix, state) {
        return HttpWrapper.get(`${endpoints.UTILITIES}/addressLookup?address=${prefix}&state=${state}`).then(response => {
            let mappedOutput = _.map(response, item => {
            return {
                        city: item.city,
                        state: item.state.abbreviation,
                        text: item.text,
                        address1: item.line1,
                        address2: item.line2,
                        zip: item.zipCode
                    }
            });

            return mappedOutput;
        });
    },

    verifyAddress(verifyAddressRequest) {
        return HttpWrapper.post(`${endpoints.UTILITIES}/verifyAddress`, verifyAddressRequest);
    }
};
