import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    activate (ids) {
        return HttpWrapper.post(`${endpoints.ENDORSEMENTS}/activate`, ids);
    },
    get(id){
        return HttpWrapper.get(`${endpoints.ENDORSEMENTS}/${id}`);
    },
    getAll(){
        return HttpWrapper.get(`${endpoints.ENDORSEMENTS}`);
    },
    copy(item) {
        return HttpWrapper.post(`${endpoints.ENDORSEMENTS}/copy/`, item, null);
    },
    save (item, changes=[]) {
        return HttpWrapper.put(`${endpoints.ENDORSEMENTS}`, { data: item, changes} );
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.ENDORSEMENTS}/delete`, ids);
    },
    saveRate (item, changes) {
        return HttpWrapper.put(`${endpoints.ENDORSEMENTS}/rate`, { data: item, changes} );
    },
    deleteRate (ids) {
        return HttpWrapper.post(`${endpoints.ENDORSEMENTS}/deleteRate`, ids);
    },
};
