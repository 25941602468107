import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getAll(){
        return HttpWrapper.get(`${endpoints.MONEYMOVEMENTAPPROVALTHRESHOLDS}`);
    },
    saveMoneyMovementApprovalThreshold (approvalThreshold, changes) {
        return HttpWrapper.put(`${endpoints.MONEYMOVEMENTAPPROVALTHRESHOLDS}`, { data: approvalThreshold, changes} );              
    },
    deleteMoneyMovementApprovalThreshold (approvalThresholdID) {              
        return HttpWrapper.delete(`${endpoints.MONEYMOVEMENTAPPROVALTHRESHOLDS}/${approvalThresholdID}`);              
    },
    deleteMoneyMovementApprovalThresholds (ids) {
        return HttpWrapper.post(`${endpoints.MONEYMOVEMENTAPPROVALTHRESHOLDS}/delete`, ids);
    },
    activateMoneyMovementApprovalThresholds(approvalThresholdIDs) {
        return HttpWrapper.post(`${endpoints.MONEYMOVEMENTAPPROVALTHRESHOLDS}/activateApprovalThresholds`, approvalThresholdIDs);
    },
};
