import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getCoverLetters(){
        return HttpWrapper.get(`${endpoints.CHECK_COVER_LETTERS}`);
    },
    getCoverLettersForOrder(ordersID){
        return HttpWrapper.get(`${endpoints.CHECK_COVER_LETTERS}/orders/${ordersID}`);
    },
    saveCoverLetter(checkCoverLetter, changes) {        
        return HttpWrapper.put(`${endpoints.CHECK_COVER_LETTERS}`, { data: checkCoverLetter, changes} );              
    },
    deleteCoverLetter(checkCoverLetterID) {
        return HttpWrapper.delete(`${endpoints.CHECK_COVER_LETTERS}/${checkCoverLetterID}`);              
    },
    deleteCoverLetters(checkCoverLetterIDs) {
        return HttpWrapper.post(`${endpoints.CHECK_COVER_LETTERS}/delete`, checkCoverLetterIDs);
    }
};