import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getOrderCategories(){
        return HttpWrapper.get(`${endpoints.ORDERCATEGORIES}`);
    },
    saveOrderCategory (orderCategory, changes) {        
        return HttpWrapper.put(`${endpoints.ORDERCATEGORIES}`, { data: orderCategory, changes} );              
    },
    deleteOrderCategory (orderCategoryId) {              
        return HttpWrapper.delete(`${endpoints.ORDERCATEGORIES}/${orderCategoryId}`);              
    },
    activateOrderCategory(orderCategoryIDs) {
        return HttpWrapper.post(`${endpoints.ORDERCATEGORIES}/activateOrderCategory`, orderCategoryIDs);
    },
};
