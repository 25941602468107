import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getAll(){
        return HttpWrapper.get(`${endpoints.NOTE_CATEGORIES}`);
    },
    saveNoteCategory (noteCategory) {
        return HttpWrapper.put(`${endpoints.NOTE_CATEGORIES}`, noteCategory );
    },
    toggleIsInactive(noteCategoryIDs) {
        return HttpWrapper.post(`${endpoints.NOTE_CATEGORIES}/toggleisinactive`, noteCategoryIDs);
    },

};