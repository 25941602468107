import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getPremium() {
        return HttpWrapper.get(`${endpoints.EXPORTS}/premiums`);
    },
    getPolicyCodes() {
        return HttpWrapper.get(`${endpoints.EXPORTS}/policyCodes`);
    },
    getEndorsmentCodes() {
        return HttpWrapper.get(`${endpoints.EXPORTS}/endorsmentCodes`);
    },
    getEndorsmentDisplay() {
        return HttpWrapper.get(`${endpoints.EXPORTS}/endorsmentGrid`);
    },
    getRatetDisplay() {
        return HttpWrapper.get(`${endpoints.EXPORTS}/rateGrid`);
    },
    fetchEndo(selectedIds) {
        return HttpWrapper.post(`${endpoints.EXPORTS}/endorsmentExport`, { data: selectedIds });
    },
    fetchRate(selectedIds) {
        return HttpWrapper.post(`${endpoints.EXPORTS}/rateExport`, { data: selectedIds });
    },
    fetchDocumentTemplate(selectedIds) {
        return HttpWrapper.post(`${endpoints.EXPORTS}/documentTemplateExport`, selectedIds);
    }
};
