import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getOrderWorkflowTask (id) {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/${id}`);
    },

    saveTask (task) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKS}/orderworkflowtask`, task);
    },

    assignToTasks (data) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/assign`, data);
    },

    getNotes (id) {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/${id}/notes`);
    },

    searchNotes (id, searchText) {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/${id}/notes/${searchText}`);
    },

    getDocuments (id) {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/${id}/documents`);
    },

    assignDocuments (taskId, documentIds) {
        return HttpWrapper.put(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/${taskId}/documents/${documentIds}`);
    },

    removeDocument (orderWorkflowTaskDocumentIds) {
        return HttpWrapper.delete(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/documents/${orderWorkflowTaskDocumentIds}`);
    },

    saveNote (note) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKS}/note`, note);
    },

    deleteNote (id) {
        return HttpWrapper.delete(`${endpoints.WORKFLOWTASKS}/note/${id}`);
    },

    getDepartments () {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/departments`, null);
    },

    // getDepartmentLookups () {
    //     return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/lookups/departments`, null);
    // },

    getLookups (orderId = 0) {
        if (orderId > 0) {return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/lookups/${orderId}`, null);}  return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/orderworkflowtask/lookups/`, null); 
    },

    getDepartmentUsers (id) {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/departments/${id}/users`, null);
    },

    getTaskTotals () {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/totals`);
    },

    getTasksLookup () {
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKS}/lookup`);
    },

    setTasksComplete (tasks) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKS}/setTaskComplete`, tasks);
    },

    setTasksNotApplicable (tasks) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKS}/setTaskNotApplicable`, tasks);
    },

    updateDashboardLastViewDate () {
        return HttpWrapper.put(`${endpoints.WORKFLOWTASKS}/dashboard/lastviewdate`, null);
    }
};
