import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getTimeTrackingAccountCodes(){
        return HttpWrapper.get(`${endpoints.ORDER_TIME_TRACKING_ACCOUNT_CODES}`);
    },
    saveOrderTimeTrackingAccountCode(orderTimeTrackingAccountCode, changes = null) {
        return HttpWrapper.put(`${endpoints.ORDER_TIME_TRACKING_ACCOUNT_CODES}`, { data: orderTimeTrackingAccountCode, changes} );
    },
    toggleIsInactive(ids) {
        return HttpWrapper.post(`${endpoints.ORDER_TIME_TRACKING_ACCOUNT_CODES}/toggleisinactive`, ids);
    },
};
