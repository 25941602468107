import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default  {
    getList () { 
        return HttpWrapper.get(`${endpoints.TRANSFER_TAX_RATES}`); 
    },    
    save (data) { 
        return HttpWrapper.post(endpoints.TRANSFER_TAX_RATES, data, null); 
    },
};
