import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getWorkflowTaskPackageCategories(){
        return HttpWrapper.get(`${endpoints.WORKFLOWTASKPACKAGECATEGORIES}`)
    },
    saveWorkflowTaskPackageCategory (wftPackageCategory) {
        return HttpWrapper.put(`${endpoints.WORKFLOWTASKPACKAGECATEGORIES}`, wftPackageCategory );
    },
    deleteWorkflowTaskPackageCategory (wftPackageCategoryIDs) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKPACKAGECATEGORIES}/delete`, wftPackageCategoryIDs);
    },
    toggleIsInactive(wftPackageCategoryIDs) {
        return HttpWrapper.post(`${endpoints.WORKFLOWTASKPACKAGECATEGORIES}/toggleisinactive`, wftPackageCategoryIDs);
    },

};