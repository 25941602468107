import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getClosingTypes(){
        return HttpWrapper.get(`${endpoints.CLOSING_TYPES}`);
    },
    saveClosingType(closingtype, changes = null) {
        return HttpWrapper.put(`${endpoints.CLOSING_TYPES}`, { data: closingtype, changes} );              
    },
    deleteClosingType (ClosingTypeId) {              
        return HttpWrapper.delete(`${endpoints.CLOSING_TYPES}/${ClosingTypeId}`);              
    },
    deleteClosingTypes (ids) {
        return HttpWrapper.post(`${endpoints.CLOSING_TYPES}/delete`, ids);
    },
    toggleIsInactive(closingTypeIds) {
        return HttpWrapper.post(`${endpoints.CLOSING_TYPES}/toggleisinactive`, closingTypeIds);
    },
};
