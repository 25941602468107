import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';
export default {
    SendCdfToMongoAsync(loanId) {
        return HttpWrapper.post(`${endpoints.WYSIWYG_CDF}/${loanId}/sendcdftomongo`);
    },
    saveCdf(data) {
        return HttpWrapper.post(`${endpoints.WYSIWYG_CDF}/saveCdf`, data,{'Content-Type': 'application/json'});
    },
    updateLoanCosts(loanId){
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/${loanId}/loan-costs`);
    },
    updateFeesTaxes(loanId){
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/${loanId}/fees-taxes`);
    },
    updateDebitCredits(loanId){
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/${loanId}/debit-credits`);
    },
    updatePaidByOthers(loanId){
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/${loanId}/paid-by-others`);
    },
    updatePayoffs(loanId){
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/${loanId}/payoffs`);
    },
    updatePremiums(loanId){
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/${loanId}/premiums`);
    },
    saveOrderSettings(orderSummary) {
        return HttpWrapper.put(`${endpoints.WYSIWYG_CDF}/order-settings`, orderSummary );
    },
    resetSettlementStatements(orderId, loanId) {
        return HttpWrapper.post(`${endpoints.WYSIWYG_CDF}/${orderId}/loan/${loanId}/reset`);
    },
};