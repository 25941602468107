import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getTitleUnits(){
        return HttpWrapper.get(`${endpoints.TITLEUNITS}`)
    },
    saveTitleUnit (titleUnit) {
        return HttpWrapper.put(`${endpoints.TITLEUNITS}`, titleUnit);
    },
    deleteTitleUnit (titleUnitID) {
        return HttpWrapper.delete(`${endpoints.TITLEUNITS}/${titleUnitID}`);
    },
    convertSignature (imageFile) {
        return HttpWrapper.post(`${endpoints.TITLEUNITS}/convertImage`, imageFile);
    },
};
