import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    get(){
        return HttpWrapper.get(`${endpoints.NUMBERRANGES}`);
    },
    // add (numberRanges) {
    //     return HttpWrapper.post(`${endpoints.NUMBERRANGES}`, numberRanges);
    // },
    save (numberRange, changes) {
        return HttpWrapper.put(`${endpoints.NUMBERRANGES}`, { data: numberRange, changes} );
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.NUMBERRANGES}/delete`, ids);
    },
    // verifyInUse (numberRangesId) {
    //     return HttpWrapper.get(`${endpoints.NUMBERRANGES}/inUse/${numberRangesId}`);
    // },
};
