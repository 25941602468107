import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getDashboardTotals () {return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/totals`);},
    getDashboardTasks (data) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/tasks`, data);},
    getTasks (orderId) {return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/${orderId}`);},
    setComplete (tasks) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/setComplete`, tasks);},
    reopen (tasks) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/reopen`, tasks);},
    toggleNotApplicable (tasks) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/toggleNotApplicable`, tasks);},
    deleteTasks (tasks) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/deleteTasks`, tasks);},
    reassign (orderId) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/reassign/${orderId}`);},
    copyTask (taskId) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/copyTask/${taskId}`);},
    copyTasks (tasks) {return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/copyTasks`, tasks);},

    getTaskNotificationInfo (countsOnly = true) {
        return countsOnly
            ? HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/notifications/counts`, null)
            : HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/notifications`, null);
    },

    getOrderWorkflowTask (id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/${id}`);
    },

    getOrderWorkflowTaskData (id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/data/${id}`);
    },

    getOrderWorkflowPredefinedTask (orderId, id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/${orderId}/orderworkflowtask/predefined/${id}`);
    },

    saveTask (task) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask`, task);
    },

    saveTaskPackages (taskPackages, orderId) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/orderworkflowtaskpackages/${orderId}`, taskPackages);
    },

    assignTasksToMe (data) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/assignme`, data);
    },

    assignToTasks (data) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/assign`, data);
    },

    getNotes (id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/${id}/notes`);
    },

    searchNotes (id, searchText) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/${id}/notes/${searchText}`);
    },

    getDocuments (id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/${id}/documents`);
    },

    assignDocuments (taskId, documentIds) {
        return HttpWrapper.put(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/${taskId}/documents`, documentIds, null);
    },

    removeDocument (orderWorkflowTaskDocumentIDs) {
        return HttpWrapper.delete(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/documents/${orderWorkflowTaskDocumentIDs}`);
    },

    saveNote (note) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/note`, note);
    },

    deleteNote (ids) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/deleteNote`, ids);
    },

    getDepartments () {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/departments`, null);
    },


    getLookups (orderId = 0) {
        if (orderId > 0) {return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/lookups/${orderId}`, null);}  return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/lookups/`, null);
    },

    getDepartmentUsers (id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/departments/${id}/users`, null);
    },

    getDepartmentUsersByDepartmentIds (ids) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/departments/users`, ids);
    },

    updateDashboardLastViewDate () {
        return HttpWrapper.put(`${endpoints.ORDER_WORKFLOW}/dashboard/lastviewdate`, null);
    },

    getDepartmentsByUserId (userId = 0, isReportTree = false) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/departments/${userId}/${isReportTree}`, null);
    },

    getTaskEmailRecipients(id) {
        return HttpWrapper.get(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/${id}/recipients`);
    },

    updateDueDates (items) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/updateduedates`, items );
    },
    updateStartDates (items) {
        return HttpWrapper.post(`${endpoints.ORDER_WORKFLOW}/orderworkflowtask/updateStartdates`, items );
    },
};
