import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default{
    getConsolidatedCheckData(request) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}/data/`, request, null);
    },
    // getConsolidatedChecks(bankId=0) {
    //     return HttpWrapper.get(`${endpoints.CONSOLIDATED_CHECKS}/bank/${bankId}`);
    // },
    getUnassignedChecks(bankId=0){
        return HttpWrapper.get(`${endpoints.CONSOLIDATED_CHECKS}/bank/${bankId}/unassigned`);
    },
    getAssignedChecks(id) {
        return HttpWrapper.get(`${endpoints.CONSOLIDATED_CHECKS}/${id}/checks`);
    },
    createCheck(data) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}`, data);
    },
    updateAssignedChecks(data) {
        return HttpWrapper.put(`${endpoints.CONSOLIDATED_CHECKS}/checks`, data);
    },
    deleteChecks(data) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}/delete`, data);
    },
    savePayeeCompany (id, companyId) {
        return HttpWrapper.put(`${endpoints.CONSOLIDATED_CHECKS}/${id}/payeecompany/${companyId}`, null);
    },
    undoChecks(data) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}/undo`, data);
    },
    voidChecks(data) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}/void`, data);
    },
    saveCheckMemo (data) {
        return HttpWrapper.put(`${endpoints.CONSOLIDATED_CHECKS}/memo`, data);
    },
    saveCheckStatus (checksIDs, checkStatus) {
        return HttpWrapper.put(`${endpoints.CONSOLIDATED_CHECKS}/status/${checkStatus}`, checksIDs, null);
    },
    printChecks(data) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}/print`, data);
    },
    validateDateRange(checkDate) {
        return HttpWrapper.get(`${endpoints.CONSOLIDATED_CHECKS}/${checkDate}`);
    },
    transferToFile(id) {
        return HttpWrapper.get(`${endpoints.CONSOLIDATED_CHECKS}/${id}/filetransfer`);
    },
    transferToWire(id, checkRequest) {
        return HttpWrapper.post(`${endpoints.CONSOLIDATED_CHECKS}/${id}/wiretransfer`, checkRequest);
    }
}