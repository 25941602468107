import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getConfiguration(){
        return HttpWrapper.get(`${endpoints.READY2CLOSE_CONFIGURATION}`);
    },
    saveR2CConfiguration(data, changes) {
        return HttpWrapper.put(`${endpoints.READY2CLOSE_CONFIGURATION}`, { data, changes} );
    },
    pushConfiguration(){
        return HttpWrapper.post(`${endpoints.READY2CLOSE_CONFIGURATION}`);
    },
};
