import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getSigningStatuses(){
        return HttpWrapper.get(`${endpoints.SIGNING_STATUSES}`);
    },
    saveSigningStatus(signingStatus, changes = null) {
        return HttpWrapper.put(`${endpoints.SIGNING_STATUSES}`, { data: signingStatus, changes} );              
    },
    deleteSigningStatus (signingStatusId) {              
        return HttpWrapper.delete(`${endpoints.SIGNING_STATUSES}/${signingStatusId}`);              
    },
    deleteSigningStatuses (signingStatusIds) {
        return HttpWrapper.post(`${endpoints.SIGNING_STATUSES}/delete`, signingStatusIds);
    },
    toggleIsInactive(signingStatusIds) {
        return HttpWrapper.post(`${endpoints.SIGNING_STATUSES}/toggleisinactive`, signingStatusIds);
    },
};
