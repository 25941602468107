import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getBranches(){
        return HttpWrapper.get(`${endpoints.BRANCHES}`);
    },
    saveBranch(branch, changes = null) {
        return HttpWrapper.put(`${endpoints.BRANCHES}`, { data: branch, changes} );
    },
    deleteBranch (branchId) {
        return HttpWrapper.delete(`${endpoints.BRANCHES}/${branchId}`);
    },
};
