import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getEscheatDormancyPeriods(){
        return HttpWrapper.get(`${endpoints.ESCHEAT_DORMANCY_PERIOD}`);
    },
    saveEscheatDormancyPeriod(escheatDormancyPeriod, changes = null) {
        return HttpWrapper.put(`${endpoints.ESCHEAT_DORMANCY_PERIOD}`, { data: escheatDormancyPeriod, changes} );
    },
    deleteEscheatDormancyPeriod (escheatDormancyPeriodId) {
        return HttpWrapper.delete(`${endpoints.ESCHEAT_DORMANCY_PERIOD}/${escheatDormancyPeriodId}`);
    },
    deleteEscheatDormancyPeriods (ids) {
        return HttpWrapper.post(`${endpoints.ESCHEAT_DORMANCY_PERIOD}/delete`, ids);
    },
    toggleIsInactive(escheatDormancyPeriodIds) {
        return HttpWrapper.post(`${endpoints.ESCHEAT_DORMANCY_PERIOD}/toggleisinactive`, escheatDormancyPeriodIds);
    },
};
