import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getLookups (orderId) { return HttpWrapper.get(`${endpoints.RECORDING_FEES}/Lookups`); },
    getList (loanId) { return HttpWrapper.get(`${endpoints.RECORDING_FEES}/getbyloan/${loanId}`); },
    pullList (loanId) { return HttpWrapper.get(`${endpoints.RECORDING_FEES}/pullforloan/${loanId}`); },
    save (data) { return HttpWrapper.post(endpoints.RECORDING_FEES, data, null); },
    calculateRatesAndFees (data) { return HttpWrapper.post(`${endpoints.RECORDING_FEES}/calculateRatesAndFees`, data, null); },
    validateRatesAndFees (loanId) { return HttpWrapper.post(`${endpoints.RECORDING_FEES}/validateRatesAndFees/${loanId}`); },
    getQuestions (loanId) { return HttpWrapper.post(`${endpoints.RECORDING_FEES}/getQuestions/${loanId}`); },
    answerQuestions (loanId, data) { return HttpWrapper.post(`${endpoints.RECORDING_FEES}/answerQuestions/${loanId}`, data); },
    getQuestionsStatus(loanId) {return HttpWrapper.post(`${endpoints.RECORDING_FEES}/getQuestionsStatus/${loanId}`);},
    markQuestionsViewed(loanId,data) {return HttpWrapper.post(`${endpoints.RECORDING_FEES}/markQuestionsViewed/${loanId}`, data);},
    clearQuestions(loanId) {return HttpWrapper.delete(`${endpoints.RECORDING_FEES}/clearQuestions/${loanId}`);}
};
