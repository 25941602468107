import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getMortgageClauseTypes(){
        return HttpWrapper.get(`${endpoints.MORTGAGE_CLAUSE_TYPES}`);
    },
    saveMortgageClauseType(mortgageClauseType, changes = null) {
        return HttpWrapper.put(`${endpoints.MORTGAGE_CLAUSE_TYPES}`, { data: mortgageClauseType, changes} );
    },
    deleteMortgageClauseType (mortgageClauseTypeId) {
        return HttpWrapper.delete(`${endpoints.MORTGAGE_CLAUSE_TYPES}/${mortgageClauseTypeId}`);
    },
    deleteMortgageClauseTypes (ids) {
        return HttpWrapper.post(`${endpoints.MORTGAGE_CLAUSE_TYPES}/delete`, ids);
    },
    toggleIsInactive(mortgageClauseTypeIds) {
        return HttpWrapper.post(`${endpoints.MORTGAGE_CLAUSE_TYPES}/toggleisinactive`, mortgageClauseTypeIds);
    },
};
