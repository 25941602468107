import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getFileScanCategorySecurityUsers(fileScanCategoryId){        
        return HttpWrapper.get(`${endpoints.FILESCANCATEGORYSECURITYUSERS}/${fileScanCategoryId}`);
    },
    getAllFileScanCategorySecurityUsers(fileScanCategoryId){        
        return HttpWrapper.get(`${endpoints.FILESCANCATEGORYSECURITYUSERS}/all/${fileScanCategoryId}`);
    },   
    saveFileScanCategorySecurityUsers(fileScanCategorySecurityUsers, changes = null) {
        return HttpWrapper.put(`${endpoints.FILESCANCATEGORYSECURITYUSERS}`, { data: fileScanCategorySecurityUsers, changes} );              
    },   
};
