import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getTransactionTypes(){
        return HttpWrapper.get(`${endpoints.TRANSACTION_TYPES}`);
    },
    saveTransactionType(transactiontype, changes = null) {
        return HttpWrapper.put(`${endpoints.TRANSACTION_TYPES}`, { data: transactiontype, changes} );              
    },
    deleteTransactionType (transactionTypeId) {              
        return HttpWrapper.delete(`${endpoints.TRANSACTION_TYPES}/${transactionTypeId}`);              
    },
    deleteTransactionTypes (ids) {
        return HttpWrapper.post(`${endpoints.TRANSACTION_TYPES}/delete`, ids);
    },
    toggleIsInactive(transactionTypeIds) {
        return HttpWrapper.post(`${endpoints.TRANSACTION_TYPES}/toggleisinactive`, transactionTypeIds);
    },
};
