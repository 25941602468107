import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getHudStatuses(ignoreActiveUser = false){
        if(ignoreActiveUser)
            return HttpWrapper.get(`${endpoints.HUD_STATUSES}?IgnoreActiveUser=true`);
        return HttpWrapper.get(`${endpoints.HUD_STATUSES}`);
    },
    saveHudStatus(hudStatus, changes = null) {
        return HttpWrapper.put(`${endpoints.HUD_STATUSES}`, { data: hudStatus, changes} );              
    },
    deleteHudStatuses(ids) {
        return HttpWrapper.post(`${endpoints.HUD_STATUSES}/delete`, ids);
    },
    deleteHudStatus(hudStatusID) {              
        return HttpWrapper.delete(`${endpoints.HUD_STATUSES}/${hudStatusID}`);              
    },
};

