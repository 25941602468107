import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getSchedulerDefaults(){
        return HttpWrapper.get(`${endpoints.SCHEDULERDEFAULTS}`);
    },
    saveSchedulerDefault (schedulerDefault, changes) {        
        return HttpWrapper.put(`${endpoints.SCHEDULERDEFAULTS}`, { data: schedulerDefault, changes} );              
    },    
};