import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    uploadRateContent( extractedXml) {
        return HttpWrapper.post(`${endpoints.IMPORTUPDATE}/uploadRate`, { data: extractedXml} );
    },

    uploadEndorsementContent(extractedXml) {
        return HttpWrapper.post(`${endpoints.IMPORTUPDATE}/uploadEndorsement`, { data: extractedXml} );
    },

    importDocumentTemplates(extractedXml, overwrite) {
        return overwrite
            ? HttpWrapper.post(`${endpoints.IMPORTUPDATE}/documenttemplates/overwrite`, { data: extractedXml} )
            : HttpWrapper.post(`${endpoints.IMPORTUPDATE}/documenttemplates`, { data: extractedXml} );
    },

    save(extractedXml) {
        return HttpWrapper.post(`${endpoints.IMPORTUPDATE}`, { data: extractedXml} );
    },

    validateArcImport (arcIntegrationModelDtos) {
        return HttpWrapper.post(`${endpoints.IMPORTUPDATE}/arc/validate`, arcIntegrationModelDtos);
    },

    processArcImport(categorizedArcDocuments){
        return HttpWrapper.put(`${endpoints.IMPORTUPDATE}/arc/process`, categorizedArcDocuments);
    },
 };