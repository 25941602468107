import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    /**
     * Retrieves the default system settings for the system associated with the current user.
     * @return {Promise} Promise for an object containing all the default system settings.
     */
    getBiSettings: () => {
        return HttpWrapper.get(`${endpoints.BISETTINGS}/settings`);
    },
    updateBiSettings (settings, changes) {        
        return HttpWrapper.put(`${endpoints.BISETTINGS}`, { data: settings, changes} );              
    },
};
