import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    get(id){
        return HttpWrapper.get(`${endpoints.PRORATE_REGION_DEFAULTS}/${id}`);
    },
    save (prorateRegionDefaults, changes) {        
        return HttpWrapper.put(`${endpoints.PRORATE_REGION_DEFAULTS}`, { data: prorateRegionDefaults, changes} );              
    },
    saveItem (prorateDefault, changes) {        
        return HttpWrapper.put(`${endpoints.PRORATE_REGION_DEFAULTS}/item`, { data: prorateDefault, changes} );              
    }
};
