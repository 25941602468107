import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getFileScanCategories(){
        return HttpWrapper.get(`${endpoints.FILESCANCATEGORIES}`);
    },
    saveFileScanCategories(fileScanCategory, changes = null) {
        return HttpWrapper.put(`${endpoints.FILESCANCATEGORIES}`, { data: fileScanCategory, changes} );
    },
    deleteFileScanCategories (ids) {
        return HttpWrapper.post(`${endpoints.FILESCANCATEGORIES}/delete`, ids);
    },
    hasExistingDocuments (ids) {              
        return HttpWrapper.post(`${endpoints.FILESCANCATEGORIES}/hasexistingdocuments`, ids);
    }
};
