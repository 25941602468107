import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    deleteAdjustments(reconciliationID, ids) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/deleteAdjustments`, ids, null);
    },

    deleteReconciliations(ids) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/delete`, ids, null);
    },

    finalizeReconciliations(ids) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/finalize`, ids, null);
    },

    getPriorReconciliations (bankCompanyID, reconciliationID) {
        return HttpWrapper.get(`${endpoints.RECONCILIATIONS}/priorReconciliations/${bankCompanyID}/${reconciliationID}`, null);
    },

    getData(id) {
        return HttpWrapper.get(`${endpoints.RECONCILIATIONS}/data/${id}`, null);
    },

    getAllReconciliations (bankCompanyID) {
        return HttpWrapper.get(`${endpoints.RECONCILIATIONS}/${bankCompanyID}`, null);
    },

    getReconciliations (bankCompanyID, completed, fromDate, toDate) {
        return HttpWrapper.get(`${endpoints.RECONCILIATIONS}/${bankCompanyID}/${completed}/${fromDate}/${toDate}`, null);
    },

    moveChecks(reconciliationID, ids, newReconciliationID) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/moveChecks/${newReconciliationID}`, ids, null);
    },

    moveDeposits(reconciliationID, ids, newReconciliationID) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/moveDeposits/${newReconciliationID}`, ids, null);
    },

    removeChecks(reconciliationID, ids) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/removeChecks`, ids, null);
    },

    removeDeposits(reconciliationID, ids) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/removeDeposits`, ids, null);
    },

    save (data, changes = null) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/save`, { data: data, changes: changes}, null);
    },

    saveAdjustment (data, changes = null) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/saveAdjustment`, { data: data, changes: changes}, null);
    },

    selectChecks(reconciliationID, ids, select) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/selectChecks/${select}`, ids, null);
    },

    selectDeposits(reconciliationID, ids, select) {
        return HttpWrapper.post(`${endpoints.RECONCILIATIONS}/${reconciliationID}/selectDeposits/${select}`, ids, null);
    },

    refreshReconciliation(id) {
        return HttpWrapper.get(`${endpoints.RECONCILIATIONS}/refresh/${id}`, null);
    },
};
