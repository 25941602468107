import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getAll(){
        return HttpWrapper.get(`${endpoints.READY2CLOSE_SYSTEM}`);
    },
    get(r2cSystemID){
        return HttpWrapper.get(`${endpoints.READY2CLOSE_SYSTEM}/${r2cSystemID}`);
    },
    saveR2CSystem(r2cSystem, changes) {
        return HttpWrapper.put(`${endpoints.READY2CLOSE_SYSTEM}`, { data: r2cSystem, changes} );              
    },
    deleteR2CSystem(r2cSystemID) {              
        return HttpWrapper.delete(`${endpoints.READY2CLOSE_SYSTEM}/${r2cSystemID}`);              
    },
};
