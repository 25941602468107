import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    // CDF addendums
    getAddendums(loanId) { return HttpWrapper.get(`${endpoints.ADDENDUMS}/${loanId}`); },
    getCdfLines(loanId) { return HttpWrapper.get(`${endpoints.ADDENDUMS}/cdflines/${loanId}`); },
    addAddendum (loanId, data) { return HttpWrapper.post(`${endpoints.ADDENDUMS}/${loanId}`, data); },
    updateAddendum (data) { return HttpWrapper.put(`${endpoints.ADDENDUMS}`, data); },
    deleteAddendum(addendumId){ return HttpWrapper.delete(`${endpoints.ADDENDUMS}/${addendumId}`); },
    deleteAddendums(addendumIds, loanId){ return HttpWrapper.post(`${endpoints.ADDENDUMS}/delete/${loanId}`, addendumIds); },

    // Hud Addendum Main apis
    getHudAddendumsByLoan(loanId)  { return HttpWrapper.get(`${endpoints.HUD_ADDENDUMS}/${loanId}`); },
    saveDeleteHudAddendums (items, deletedItems) { return HttpWrapper.post(`${endpoints.HUD_ADDENDUMS}/fullUpdate`, {items, deletedItems}); },
    getHudMainId(loanId)  { return HttpWrapper.get(`${endpoints.HUD_ADDENDUMS}/hudMainId/${loanId}`); },
    getHudLines(loanId)  { return HttpWrapper.get(`${endpoints.HUD_ADDENDUMS}/hudlines/${loanId}`); },

    // Hud Addendum detail apis
    addAddendumDetail (detail) { return HttpWrapper.post(`${endpoints.HUD_ADDENDUMS}/detail`, detail); },
    updateAddendumDetail (detail) { return HttpWrapper.put(`${endpoints.HUD_ADDENDUMS}/detail`, detail); },
    deleteAddendumDetails(details){ return HttpWrapper.post(`${endpoints.HUD_ADDENDUMS}/detail/delete`, details); },
};