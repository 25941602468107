import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getStaff(){
        return HttpWrapper.get(`${endpoints.STAFF}`);
    },
    saveStaff(staff, changes = null) {
        return HttpWrapper.put(`${endpoints.STAFF}`, { data: staff, changes} );              
    },     
    addFromUser (ids) {
        return HttpWrapper.post(`${endpoints.STAFF}/addFromUser`, ids);
    },  
    deleteStaff (ids) {
        return HttpWrapper.post(`${endpoints.STAFF}/delete`, ids);
    },
    saveNotary(staff, changes = null) {
        return HttpWrapper.put(`${endpoints.STAFF}/notary`, { data: staff, changes} );              
    },     
    
    getAvailableUsers(){
        return HttpWrapper.get(`${endpoints.STAFF}/availableUsers`);
    },
    
    toggleIsInactive(staffIds) {
        return HttpWrapper.post(`${endpoints.STAFF}/toggleisinactive`, staffIds);
    },
};
