import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    activateCompanies(companyIDs) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/activateCompanies`, companyIDs);
    },

    getCompany(companyId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/${companyId}`);
    },

    getCompanyDetail(companyId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/details/${companyId}`);
    },

    getContactDetail(contactId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/contactdetails/${contactId}`);
    },

    saveContactDetail(contactDetail, changes) {
        if (contactDetail.contactInfo) {
            const isNewContact = contactDetail.contactInfo.contactID === 0;
            if (isNewContact) {
                return HttpWrapper.post(`${endpoints.COMPANIES}/addContactDetail`, contactDetail);
            }
            return HttpWrapper.put(`${endpoints.COMPANIES}/updateContactDetail`, { data: contactDetail, changes});
        }
    },

    getContact(contactId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/contacts/${contactId}`);
    },

    getAutocompleteResults (data) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/autocomplete`, data);
    },

    activateContacts(contactIDs) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/activateContacts`, contactIDs);
    },

    getContacts (companyId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/${companyId}/contacts`);
    },
    getUnderwriter (companyId){
        return HttpWrapper.get(`${endpoints.COMPANIES}/underwriter/${companyId}`);
    },
    getCorrectedFiles(titleCompanyIds, fromDate, toDate) {
        let requestData = {
            titleCompanyIds,
            fromDate,
            toDate
        };
        return HttpWrapper.post(`${endpoints.COMPANIES}/title/correctedfiles`, requestData);
    },
    create1099File(request) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/title/file1099`, request);
    },
    getTitleCompany (companyId){
        return HttpWrapper.get(`${endpoints.COMPANIES}/title/${companyId}`);
    },
    getEscrowAccount (companyId){
        return HttpWrapper.get(`${endpoints.COMPANIES}/bank/${companyId}`);
    },

    getLookups (companyId) {
        if (typeof(companyId) == 'undefined') { companyId = 0; }
        return HttpWrapper.get(`${endpoints.COMPANIES}/${companyId}/lookups`);
    },

    deleteCompany(companyId) {
        return HttpWrapper.delete(`${endpoints.COMPANIES}/${companyId}`);
    },

    deleteCompanies(ids) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/delete`, ids);
    },

    deleteContact(contactId) {
        return HttpWrapper.delete(`${endpoints.COMPANIES}/contacts/${contactId}`);
    },

    deleteContacts(toBeDeletedContactIds) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/contacts/deleteContacts`, toBeDeletedContactIds);
    },

    saveCompanyDetail(companyDetail, changes) {
        if (companyDetail.companyInfo) {
            const isNewCompany = companyDetail.companyInfo.companyID === 0;
            if (isNewCompany) {
                return HttpWrapper.post(`${endpoints.COMPANIES}/add`, companyDetail);
            }
            return HttpWrapper.put(`${endpoints.COMPANIES}/update`, { data: companyDetail, changes });
        }
    },

    getLogo(companyId){
        return HttpWrapper.get(`${endpoints.COMPANIES}/${companyId}/logo`);
    },

    updateLogo (companyId, imageFile) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/${companyId}/logo`, imageFile);
    },

    saveLogo (companyId, imageDataModel) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/${companyId}/logo/save`, imageDataModel);
    },

    removeLogo (companyId) {
        return HttpWrapper.delete(`${endpoints.COMPANIES}/${companyId}/logo`);
    },

    saveListCodes (companyListCodes) {
        return HttpWrapper.put(`${endpoints.COMPANIES}/listCodes/save`, companyListCodes);
    },

    saveWebOrderLayoutTemplate (companyWebOrderLayoutTemplate) {
        return HttpWrapper.put(`${endpoints.COMPANIES}/companyWebOrderLayoutTemplate/save`, companyWebOrderLayoutTemplate);
    },

    fix (companyInfo) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/fix`, companyInfo);
    },
    //RQO-5438

    getUnderwriterRemitLockDates() {
        return HttpWrapper.get(`${endpoints.COMPANIES}/underwriterremitlockdate`);
    },

    saveUnderwriterRemitLockDates(underwriterRemitLockDateDto) {
        return HttpWrapper.put(`${endpoints.COMPANIES}/underwriterremitlockdate`, underwriterRemitLockDateDto);
    },

    /*TODO: CONSOLIDATE SEARCH CALLS */

    findCompanies(searchRequest) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/find`, searchRequest);
    },

    search (searchRequest) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/search`, searchRequest);
    },

    searchContacts (request) {
        return HttpWrapper.post(`${endpoints.COMPANIES}/contacts`, request);
    },

    /************************************/

    getDepositSlipTemplateByOrderId(orderId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/order/${orderId}/deposit-slip-template`, null);
    },

    getDepositSlipTemplateByCompanyId(companyId) {
        return HttpWrapper.get(`${endpoints.COMPANIES}/bank/${companyId}/deposit-slip-template`, null);
    },
};
