import { HttpWrapper } from "../shared/services/rest.service";
import { AUDITLOG } from "./endpoints";
import RestClient from "../shared/utilities/RestClient";

const AUDIT_LOG_BASE_URL = appSettings.auditLogBaseUrl;
const auditLogClient = new RestClient({
    baseUrl: _.isEmpty(AUDIT_LOG_BASE_URL)
        ? `${window.location.origin}/rq-auditlog`
        : AUDIT_LOG_BASE_URL
});

export default {
    search: searchQuery => auditLogClient.post("entries/search", searchQuery),
    getTableFieldLookups: () => HttpWrapper.get(`${AUDITLOG}/lookups`)
};

// export default {
//     search: searchQuery => HttpWrapper.post(`${AUDITLOG}/entries`, searchQuery),

//     // updateComment: data => HttpWrapper.post(`${AUDITLOG}/comment`, data, null),

//     // createLabel: data => HttpWrapper.post(`${AUDITLOG}/label`, data, null),

//     // rollbackCommitment: auditLogId => HttpWrapper.get(`${AUDITLOG}/commitments/rollback/${auditLogId}`)
// };
