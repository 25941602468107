import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getLookups() { return HttpWrapper.get(`${endpoints.ORDER_SETTLEMENT_FEES}/lookups`); },
    getByOrderId (orderId) { return HttpWrapper.get(`/orders/${orderId}${endpoints.ORDER_SETTLEMENT_FEES}`); },
    getByLoanId (loanId) { return HttpWrapper.get(`/loans/${loanId}${endpoints.ORDER_SETTLEMENT_FEES}`); },
    getAvailableFees (orderId, loanId) { return HttpWrapper.get(`/orders/${orderId}${endpoints.SETTLEMENT_FEES}/${loanId}`); },
    add (data) { return HttpWrapper.post(endpoints.ORDER_SETTLEMENT_FEES, data, null); },
    save (data) { return HttpWrapper.post(`${endpoints.ORDER_SETTLEMENT_FEES}/save`, data, null); },
    delete (id) { return HttpWrapper.delete(`${endpoints.ORDER_SETTLEMENT_FEES}/${id}`); },
    deleteFees (ids) { return HttpWrapper.post(`${endpoints.ORDER_SETTLEMENT_FEES}/deleteFees`, ids, null); }
};
