import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getRegions(){
        return HttpWrapper.get(`${endpoints.REGIONS}`);
    },
    saveRegion (region, changes) {
        return HttpWrapper.put(`${endpoints.REGIONS}`, { data: region, changes} );
    },
    deleteRegion (regionId) {
        return HttpWrapper.delete(`${endpoints.REGIONS}/${regionId}`);
    },
    getListFormats(regionId=null) {
        if(_.isNil(regionId))
            return HttpWrapper.get(`${endpoints.REGIONS}/listformats`);
        else
            return HttpWrapper.get(`${endpoints.REGIONS}/${regionId}/listformats`);
    },
    processListFormats(listFormats) {
        return HttpWrapper.post(`${endpoints.REGIONS}/listformats`, listFormats);
    },
    getNextRegID() {
        return HttpWrapper.get(`${endpoints.REGIONS}/nextRegId`);        
    },
};
