import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    activate (ids) {
        return HttpWrapper.post(`${endpoints.CONFIGWORKFLOWTASKS}/activate`, ids);
    },
    get(){
        return HttpWrapper.get(`${endpoints.CONFIGWORKFLOWTASKS}`);
    },
    getLookups(){
        return HttpWrapper.get(`${endpoints.CONFIGWORKFLOWTASKS}/getLookups`);
    },
    save (ConfigWorkflowTasksDataDto, changes) {        
        return HttpWrapper.post(`${endpoints.CONFIGWORKFLOWTASKS}`, { data: ConfigWorkflowTasksDataDto, changes} );              
    },
    applyToExisting(updatedExistingItems){
        return HttpWrapper.post(`${endpoints.CONFIGWORKFLOWTASKS}/applyexisting`, { data: updatedExistingItems } );              
    }
};
