import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getVestingInformation(){
        return HttpWrapper.get(`${endpoints.VESTINGINFORMATION}`);
    },
    saveVestingInformation (vestingInformation, changes) {        
        return HttpWrapper.put(`${endpoints.VESTINGINFORMATION}`, { data: vestingInformation, changes} );              
    },
    deleteVestingInformation (vestingInformationID) {              
        return HttpWrapper.delete(`${endpoints.VESTINGINFORMATION}/${vestingInformationID}`);              
    },
    activateVestingInformation(VestingInformationIDs) {
        return HttpWrapper.post(`${endpoints.VESTINGINFORMATION}/activateVestingInformation`, VestingInformationIDs);
    },
};
