import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getLookups () { return HttpWrapper.get(`${endpoints.PRORATIONS}/Lookups`); },    
    getProrationData (orderId) { return HttpWrapper.get(`${endpoints.PRORATIONS}/${orderId}`); },    
    addProration (proration) { return HttpWrapper.post(`${endpoints.PRORATIONS}`, proration);  },    
    calculateAll (request) { return HttpWrapper.put(`${endpoints.PRORATIONS}/CalculateAll`, request); },    
    calculateDates (proration) { return HttpWrapper.put(`${endpoints.PRORATIONS}/CalculateDates`, proration); },    
    calculateProration (proration) { return HttpWrapper.put(`${endpoints.PRORATIONS}/Calculate`, proration); },    
    saveProration (proration) { return HttpWrapper.put(`${endpoints.PRORATIONS}`, proration); },    
    deleteProration (prorationId) { return HttpWrapper.delete(`${endpoints.PRORATIONS}/${prorationId}`); }, 
    deleteProrations (prorationIds, orderId) { return HttpWrapper.post(`${endpoints.PRORATIONS}/delete/${orderId}`, prorationIds); }, 
    updateAndCalcAll(request) { return HttpWrapper.post(`${endpoints.PRORATIONS}/updateandcalcall`,request) },
    updateSelected(request) {
        return HttpWrapper.post(`${endpoints.PRORATIONS}/updateSelected`, request);
    },
    mapData(loanId) { return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf`); },
};
