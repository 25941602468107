import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    publishOrder(ordersID){
        return HttpWrapper.post(`${endpoints.READY2CLOSE_CHANGE_TRACKING}/${ordersID}`);
    },
    getR2cPortalUrl(ordersID, fromRoute){
        return HttpWrapper.get(`${endpoints.READY2CLOSE_CHANGE_TRACKING}/${ordersID}/${fromRoute}`);
    },
    unpublishOrder(ordersID){
        return HttpWrapper.put(`${endpoints.READY2CLOSE_CHANGE_TRACKING}/${ordersID}`);
    },
    downloadR2CActivity(ordersID){
        return HttpWrapper.get(`${endpoints.READY2CLOSE_CHANGE_TRACKING}/${ordersID}`);
    },
    pushToR2C(ordersID){
        return HttpWrapper.post(`${endpoints.READY2CLOSE_CHANGE_TRACKING}/${ordersID}/push`);
    }
};