import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getCompanyRoles(){
        return HttpWrapper.get(`${endpoints.COMPANY_ROLES}`);
    },
    saveCompanyRoles (companyRoles, changes) {
        return HttpWrapper.put(`${endpoints.COMPANY_ROLES}`, { data: companyRoles, changes} );
    },
    deleteCompanyRoles (roleTypeID) {
        return HttpWrapper.delete(`${endpoints.COMPANY_ROLES}/${roleTypeID}`);
    },
    toggleActivate(roleTypeIDs) {
        return HttpWrapper.post(`${endpoints.COMPANY_ROLES}/activate`, roleTypeIDs);
    },
};
