import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getGridStates () {
        return HttpWrapper.get(`${endpoints.GRIDSTATES}`);
    },
    saveGridState (key, state) {
        return HttpWrapper.post(`${endpoints.GRIDSTATES}`, { data: { key: key, state: state }} );
    },
};
