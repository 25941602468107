import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getAutoConsolidatePayees(){
        return HttpWrapper.get(`${endpoints.AUTOCONSOLIDATEPAYEES}`);
    },
    saveAutoConsolidatePayee (autoConsolidatePayee, changes) {        
        return HttpWrapper.put(`${endpoints.AUTOCONSOLIDATEPAYEES}`, { data: autoConsolidatePayee, changes} );              
    },
    deleteAutoConsolidatePayee (autoConsolidatePayeeID) {              
        return HttpWrapper.delete(`${endpoints.AUTOCONSOLIDATEPAYEES}/${autoConsolidatePayeeID}`);              
    },
    deleteAutoConsolidatePayees (ids) {
        return HttpWrapper.post(`${endpoints.AUTOCONSOLIDATEPAYEES}/delete`, ids);
    },
    activateAutoConsolidatePayees(autoConsolidatePayeeIDs) {
        return HttpWrapper.post(`${endpoints.AUTOCONSOLIDATEPAYEES}/activateAutoConsolidatedPayees`, autoConsolidatePayeeIDs);
    },
};