import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    /**
     * Retrieves the default system settings for the system associated with the current user.
     * @return {Promise} Promise for an object containing all the default system settings.
     */
    getSystemDefaultData: () => {
        return HttpWrapper.get(`${endpoints.SYSTEMDEFAULT}/settings`);
    },
    getSystemLookups: () => {
        return HttpWrapper.get(`${endpoints.SYSTEMDEFAULT}/lookups`);
    },
    getSystemData: () => {
        return HttpWrapper.get(`${endpoints.SYSTEMDEFAULT}`);
    },
    updateSystemDefault (data) {
        return HttpWrapper.put(`${endpoints.SYSTEMDEFAULT}`, data );
    }
};
