import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    exportReport(options, fileType) {
        return HttpWrapper.post(`${endpoints.EXAGO}/report/${fileType}`, options);
    },
    loadReport(options) {
        return HttpWrapper.post(`${endpoints.EXAGO}/report`, options);
    },
    createSession() {
        return HttpWrapper.get(`${endpoints.EXAGO}/session`);
    },
    getReportListFromSession(sessionId) {
        return HttpWrapper.get(`${endpoints.EXAGO}/session/${sessionId}/reportlist`);
    },
    getReportById(reportID) {
        return HttpWrapper.get(`${endpoints.EXAGO}/report/${reportID}`);
    },
    getReportByPath(reportPath) {
        return HttpWrapper.get(`${endpoints.EXAGO}/report?path=${encodeURIComponent(reportPath)}`);
    },
    updateLastExecuted(reportId, lastExecuted=null) {
        return HttpWrapper.put(`${endpoints.EXAGO}/report/${reportId}/lastexecuted/${lastExecuted || ""}`);
    },
    getReportList() {
        return HttpWrapper.get(`${endpoints.EXAGO}/reportlist`);
    },
    getReportTree() {
        return HttpWrapper.get(`${endpoints.EXAGO}/tree`);
    },
    getPermissions(contentId, parentContentId) {
        return HttpWrapper.post(`${endpoints.EXAGO}/tree/permissionList`, { contentId, parentContentId });
    },
    updatePermissions(permissions) {
        return HttpWrapper.put(`${endpoints.EXAGO}/permission`, permissions);
    },
    reportParameters(reportID) {
        return HttpWrapper.get(`${endpoints.EXAGO}/report/${reportID}/parameters`);
    },
    reportParametersByPath(reportPath) {
        return HttpWrapper.get(`${endpoints.EXAGO}/report/parameters?reportPath=${encodeURIComponent(reportPath)}`);
    },
};