import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    get () {
        return HttpWrapper.get(`${endpoints.TAGS}`, null);
    },

    save(tag, changes = null) {
        return HttpWrapper.put(`${endpoints.TAGS}`, { data: tag, changes}, null);
    },

    delete(ids) {
        return HttpWrapper.post(`${endpoints.TAGS}/delete`, ids, null);
    },
};
