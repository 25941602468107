import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getWebDefaults(){
        return HttpWrapper.get(`${endpoints.WEB_DEFAULTS}`);
    },
    addWebDefaults (webDefaults) {        
        return HttpWrapper.post(`${endpoints.WEB_DEFAULTS}`, webDefaults);              
    },
    updateWebDefaults (webDefaults, changes) {        
        return HttpWrapper.put(`${endpoints.WEB_DEFAULTS}`, { data: webDefaults, changes} );              
    },
    deleteWebDefaults (webDefaultsId) {              
        return HttpWrapper.delete(`${endpoints.WEB_DEFAULTS}/${webDefaultsId}`);              
    },
};
