import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getDuplicateSearchSetups(){
        return HttpWrapper.get(`${endpoints.DUPLICATESEARCH}`);
    },
    saveDuplicateSearchSetup (duplicateSearchSetup) {
        return HttpWrapper.put(`${endpoints.DUPLICATESEARCH}`, duplicateSearchSetup);
    },
    deleteSearch (duplicateSearchId) {
        return HttpWrapper.delete(`${endpoints.DUPLICATESEARCH}/${duplicateSearchId}`);
    },
};
