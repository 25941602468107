import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getTimeTrackingCategories(){
        return HttpWrapper.get(`${endpoints.ORDER_TIME_TRACKING_CATEGORIES}`);
    },
    saveOrderTimeTrackingCategory(orderTimeTrackingCategory, changes = null) {
        return HttpWrapper.put(`${endpoints.ORDER_TIME_TRACKING_CATEGORIES}`, { data: orderTimeTrackingCategory, changes} );
    },
    toggleIsInactive(ids) {
        return HttpWrapper.post(`${endpoints.ORDER_TIME_TRACKING_CATEGORIES}/toggleisinactive`, ids);
    },
};
