import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default{
    getRecordsByLoan (loanId) { return HttpWrapper.get(`${endpoints.ESCROWS}/loan/${loanId}`); },
    getSettlementDataByLoan (loanId) { return HttpWrapper.get(`${endpoints.ESCROWS}/settlementData/loan/${loanId}`); },
    deleteRecord (id) { return HttpWrapper.delete(`${endpoints.ESCROWS}/${id}`); },
    saveSettlementData(data) { return HttpWrapper.post(`${endpoints.ESCROWS}/settlementData`, data); },
    saveRecords (records) { return HttpWrapper.post(`${endpoints.ESCROWS}`, records); },
    saveDeleteRecords (items, deletedItems) { return HttpWrapper.post(`${endpoints.ESCROWS}/fullUpdate`, {items, deletedItems}); },
    mapEscrowCharges(loanId) { return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf`); },
}