import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {    
    getReportsAccess() {
        return HttpWrapper.get(`${endpoints.REPORTS}/access`);
    },
    getReportTree() {
        return HttpWrapper.get(`${endpoints.REPORTS}/tree`);
    },
    getReportByPath(reportPath) {
        return HttpWrapper.get(`${endpoints.REPORTS}/report?path=${encodeURIComponent(reportPath)}`);
    },
    exportReport(reportInfo, param) {
        reportInfo.param = JSON.stringify(param);
        return HttpWrapper.post(`${endpoints.REPORTS}/logireports/report`, reportInfo);
    },
};