import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getPropertyLayouts(){
        return HttpWrapper.get(`${endpoints.PROPERTYLAYOUTS}`);
    },
    savePropertyLayout (propertyLayout, changes) {        
        return HttpWrapper.put(`${endpoints.PROPERTYLAYOUTS}`, { data: propertyLayout, changes} );              
    },
    deletePropertyLayout (propertyLayoutID) {              
        return HttpWrapper.delete(`${endpoints.PROPERTYLAYOUTS}/${propertyLayoutID}`);              
    },
};
