import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getRecordingStatuses(){
        return HttpWrapper.get(`${endpoints.RECORDING_STATUSES}`);
    },
    saveRecordingStatus(recordingStatus, changes = null) {
        return HttpWrapper.put(`${endpoints.RECORDING_STATUSES}`, { data: recordingStatus, changes} );              
    },
    deleteRecordingStatus (recordingStatusId) {              
        return HttpWrapper.delete(`${endpoints.RECORDING_STATUSES}/${recordingStatusId}`);              
    },
    deleteRecordingStatuses (recordingStatusIds) {
        return HttpWrapper.post(`${endpoints.RECORDING_STATUSES}/delete`, recordingStatusIds);
    },
    toggleIsInactive(recordingStatusIds) {
        return HttpWrapper.post(`${endpoints.RECORDING_STATUSES}/toggleisinactive`, recordingStatusIds);
    },
};
