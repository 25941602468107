import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getPayoffData (loanId) { return HttpWrapper.get(`${endpoints.PAYOFFS}/${loanId}`); },
    getPayoffById (payoffId) { return HttpWrapper.get(`${endpoints.PAYOFFS}/payoff/${payoffId}`); },
    addPayoff (orderId, loanId, section) { return HttpWrapper.post(`${endpoints.PAYOFFS}/addPayoff`);  },
    calculatePayoff (payoff) { return HttpWrapper.put(`${endpoints.PAYOFFS}/calculate`, payoff); },    
    savePayoff (payoff) { return HttpWrapper.put(`${endpoints.PAYOFFS}`, payoff); },    
    // deletePayoff (payoffId) { return HttpWrapper.delete(`${endpoints.PAYOFFS}/${payoffId}`); }    
    deletePayoff (orderId, toBeDeletedIds) {return HttpWrapper.post(`${endpoints.PAYOFFS}/${orderId}/deletePayoffs`, toBeDeletedIds);},

    // CDF Payoff Payment apis
    getCdfPayoffData (loanId) { return HttpWrapper.get(`${endpoints.CDF_PAYOFFS}/${loanId}`); },
    saveDeleteCdfPayoffs (items, deletedItems) { return HttpWrapper.post(`${endpoints.CDF_PAYOFFS}/fullUpdate`, {items, deletedItems}); },
};
