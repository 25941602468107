import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default{
    getByOrderId(orderId){ return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}`); },
    getDetails(orderId, invoiceId){ return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/${invoiceId}`); },
    addForOrderId(orderId){ return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}`, null); },
    update(orderId, invoice){ return HttpWrapper.put(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}`, invoice); },
    updateDetails(orderId, invoice){ return HttpWrapper.put(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/${invoice.invoiceID}`, invoice); },
    deleteInvoice(orderId, invoiceId){ return HttpWrapper.delete(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/${invoiceId}`); },
    deleteInvoices(orderId, invoiceIds){ return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/delete`, invoiceIds); },
    save(orderId, invoices){ return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/save`, invoices); },
    addCreditMemo(orderId, invoiceId){ return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/${invoiceId}/creditmemos`, null); },
    batchPaymentSearch(searchRequest){ return HttpWrapper.post(`${endpoints.INVOICES}/batchpaymentsearch`, searchRequest);},
    applyBatchPayments(batchPayments, checkAmount = 0){ return HttpWrapper.post(`${endpoints.INVOICES}/applybatchpayments?checkAmount=${checkAmount}`, batchPayments);},
    fillInvoice(orderId, invoiceId, request){ return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}${endpoints.INVOICES}/${invoiceId}/FillInvoice`, request);},
    reorderInvoiceLines(orderId, invoiceId, request){ return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}/${endpoints.INVOICES}/${invoiceId}/reorder`, request); },
}