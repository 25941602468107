import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    getAll (unVerifiedOnly=true) {
        return HttpWrapper.get(`${endpoints.TRANSFER_SLIPS}/${unVerifiedOnly}`, null);
    },

    get (bankCompanyID, unVerifiedOnly=true) {
        return HttpWrapper.get(`${endpoints.TRANSFER_SLIPS}/${bankCompanyID}/${unVerifiedOnly}`, null);
    },

    getDetail (transferSlipID) {
        return HttpWrapper.get(`${endpoints.TRANSFER_SLIPS}/data/${transferSlipID}/`, null);
    },

    delete(bankCompanyID, unVerifiedOnly, ids) {
        return HttpWrapper.post(`${endpoints.TRANSFER_SLIPS}/delete/${bankCompanyID}/${unVerifiedOnly}`, ids, null);
    },

    save (item, changes) {
        return HttpWrapper.put(`${endpoints.TRANSFER_SLIPS}/`, { data: item, changes}, null);
    },

    saveDetail (transferSlipID, ids) {
        return HttpWrapper.put(`${endpoints.TRANSFER_SLIPS}/detail/${transferSlipID}`, ids, null);
    },

    depositAvailability (ids) {
        return HttpWrapper.put(`${endpoints.TRANSFER_SLIPS}/depositAvailability`, ids, null);
    }

};