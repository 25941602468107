import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getAddressTypes() {
        return HttpWrapper.get(`${endpoints.ADDRESS_TYPES}`);
    },
    saveAddressType(addressType, changes) {        
        return HttpWrapper.put(`${endpoints.ADDRESS_TYPES}`, { data: addressType, changes} );              
    },
    deleteAddressType(addressTypeID) {
        return HttpWrapper.delete(`${endpoints.ADDRESS_TYPES}/${addressTypeID}`);              
    },
    deleteAddressTypes(addressTypeIDs) {
        return HttpWrapper.post(`${endpoints.ADDRESS_TYPES}/delete`, addressTypeIDs);
    },
    activateAddressTypes(addressTypeIDs) {
        return HttpWrapper.post(`${endpoints.ADDRESS_TYPES}/activateAddressTypes`, addressTypeIDs);
    },
};