import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    acceptDeposits(date, ids) {
        return HttpWrapper.put(`${endpoints.ESCROW_ACCOUNTING}/acceptDeposits/${date}`, ids, null);
    },

    acceptWires(ids) {
        return HttpWrapper.put(`${endpoints.ESCROW_ACCOUNTING}/acceptWires/`, ids, null);
    },

    getChecks (request) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/checks/`, request, null);
    },

    getDeposits (request) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/deposits/`, request, null);
    },

    getEscrowActivity (request) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/escrowActivity/`, request, null);
    },

    getEscrowTrialBalances (bankCompanyID) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/escrowTrialBalances/${bankCompanyID}`, null);
    },

    getEscrowUsers (bankCompanyID) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/escrowUsers/${bankCompanyID}`, null);
    },

    getOpenInvestmentAccounts (regionID, branchID) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/openInvestmentAccounts/${regionID}/${branchID}`, null);
    },

    getPositivePayBanks (request) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/positivePaySearch/`, request, null);
    },

    getPositivePayLayout (exportFormatID) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/positivePayLayout/${exportFormatID}`, null);
    },

    getPositivePayValidationSettings (exportFormatID) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/positivePayValidationSettings/${exportFormatID}`, null);
    },

    getUnverifiedDeposits () {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/unverifiedDeposits`, null);
    },

    getExpectedWires (fromDate, toDate) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/expectedWires/${fromDate}/${toDate}`, null);
    },

    getExpectedWiresOut (fromDate, toDate) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/expectedWiresOut/${fromDate}/${toDate}`, null);
    },

    getLookups (id, type) {
        return HttpWrapper.get(`${endpoints.ESCROW_ACCOUNTING}/lookups/${id}/${type}`, null);
    },

    deleteDeposits(ids) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/deleteDeposits`, ids, null);
    },

    deleteChecks(ids) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/deleteChecks`, ids, null);
    },

    processPositivePayBanks (request) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/positivePayProcess/`, request, null);
    },

    saveCheck (item, changes) {
        return HttpWrapper.put(`${endpoints.ESCROW_ACCOUNTING}/saveCheck`, {data: item, changes}, null);
    },

    saveDeposit (item, changes) {
        return HttpWrapper.put(`${endpoints.ESCROW_ACCOUNTING}/saveDeposit`, {data: item, changes}, null);
    },

    saveCheckStatusChange(request) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/saveCheckStatusChange`, request, null);
    },

    saveCheckReconciliation(reconciliationID, ids) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/saveCheckReconciliation/${reconciliationID}`, ids, null);
    },

    saveDepositDateChange(date, dateName, ids) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/saveDepositDateChange/${date}/${dateName}`, ids, null);
    },

    saveDepositReconciliation(reconciliationID, ids) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/saveDepositReconciliation/${reconciliationID}`, ids, null);
    },

    savePositivePay (item, changes) {
        return HttpWrapper.put(`${endpoints.ESCROW_ACCOUNTING}/savePositivePay`, {data: item, changes}, null);
    },

    validatePositivePay (positivePay) {
        return HttpWrapper.post(`${endpoints.ESCROW_ACCOUNTING}/validatePositivePay`, positivePay, null);
    },

    verifyDeposits (ids) {
        return HttpWrapper.put(`${endpoints.ESCROW_ACCOUNTING}/verifyDeposits`, ids, null);
    },
};
