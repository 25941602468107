import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default  {
    get(id){
        return HttpWrapper.get(`${endpoints.TRANSFER_TAX_CONFIGURATION}/${id}`);
    },
    getList () {
        return HttpWrapper.get(`${endpoints.TRANSFER_TAX_CONFIGURATION}`);
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}/delete`, ids);
    },
    save (item, changes) {
        return HttpWrapper.put(endpoints.TRANSFER_TAX_CONFIGURATION, { data: item, changes});
    },
    create (data) {
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}`, data, null);
    },
    copy(id){
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}/copy/${id}`);
    },
    saveRate (item, changes) {
        return HttpWrapper.put(`${endpoints.TRANSFER_TAX_CONFIGURATION}/rate`, { data: item, changes} );
    },
    deleteRate (ids) {
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}/deleteRate`, ids);
    },
    saveCalculationRounding (item, changes) {
        return HttpWrapper.put(`${endpoints.TRANSFER_TAX_CONFIGURATION}/calculationRounding`, { data: item, changes} );
    },
    deleteCalculationRounding (ids) {
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}/deleteCalculationRounding`, ids);
    },
    saveCoverageRounding (item, changes) {
        return HttpWrapper.put(`${endpoints.TRANSFER_TAX_CONFIGURATION}/coverageRounding`, { data: item, changes} );
    },
    deleteCoverageRounding (ids) {
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}/deleteCoverageRounding`, ids);
    },
    activateTransferTax(ids) {
        return HttpWrapper.post(`${endpoints.TRANSFER_TAX_CONFIGURATION}/activateTransferTax`, ids);
    },
};

