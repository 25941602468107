import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getOrderRateEndorsements (orderId, orderRateCalculationId) {
        return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/orderRateCalculations/${orderRateCalculationId}/orderEndorsements`, null);
    },
    getOrderEndorsements (orderId) {
        return HttpWrapper.get(`${endpoints.RATE_ENGINE}/${orderId}/orderEndorsements`, null);
    },
    addOrderEndorsements (orderId, orderRateCalculationId, endorsementIds) {
        return HttpWrapper.post(`${endpoints.RATE_ENGINE}/${orderId}/orderRateCalculations/${orderRateCalculationId}/orderEndorsements`, endorsementIds, null);
    },
    updateOrderEndorsements (orderEndorsements) {
        return HttpWrapper.put(`${endpoints.RATE_ENGINE}/orderRateCalculations/orderEndorsements`, orderEndorsements, null);
    },
    deleteOrderEndorsement (id) {
        return HttpWrapper.delete(`${endpoints.RATE_ENGINE}/orderRateCalculations/orderEndorsements/${id}`, null);
    },
    deleteOrderEndorsements(ids) {
        return HttpWrapper.post(`${endpoints.RATE_ENGINE}/orderRateCalculations/orderEndorsements/delete`, ids);
    }
};