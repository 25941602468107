import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    get(){
        return HttpWrapper.get(`${endpoints.DEFAULTCDFLINES}`);
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.DEFAULTCDFLINES}/delete`, ids);
    },
    save (defaultCDFLineDto) {
        return HttpWrapper.post(`${endpoints.DEFAULTCDFLINES}`, { data: defaultCDFLineDto } );
    },
    cdfSectionLookup () {
        return HttpWrapper.get(`${endpoints.DEFAULTCDFLINES}/cdfsectionlookup`);
    }
};
