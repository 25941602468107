import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getPayoffDescriptions(){
        return HttpWrapper.get(`${endpoints.PAYOFFDESCRIPTIONS}`);
    },
    savePayoffDescription(payoffDescriptions, changes = null) {
        return HttpWrapper.put(`${endpoints.PAYOFFDESCRIPTIONS}`, { data: payoffDescriptions, changes} );              
    },
    deletePayoffDescription (payoffDescriptionsId) {              
        return HttpWrapper.delete(`${endpoints.PAYOFFDESCRIPTIONS}/${payoffDescriptionsId}`);              
    },
    deletePayoffDescriptions (payoffDescriptionsIds) {              
        return HttpWrapper.post(`${endpoints.PAYOFFDESCRIPTIONS}/delete`, payoffDescriptionsIds);              
    }
};
