import { HttpWrapper } from "../shared/services/rest.service";
import * as endpoints from "./endpoints";

export default {

    getAll (unVerifiedOnly=true) {
        return HttpWrapper.get(`${endpoints.DEPOSIT_SLIPS}/${unVerifiedOnly}`, null);
    },

    get (bankCompanyID, unVerifiedOnly=true) {
        return HttpWrapper.get(`${endpoints.DEPOSIT_SLIPS}/${bankCompanyID}/${unVerifiedOnly}`, null);
    },

    getDetail (depositSlipID) {
        return HttpWrapper.get(`${endpoints.DEPOSIT_SLIPS}/data/${depositSlipID}/`, null);
    },

    delete(bankCompanyID, unVerifiedOnly, ids) {
        return HttpWrapper.post(`${endpoints.DEPOSIT_SLIPS}/delete/${bankCompanyID}/${unVerifiedOnly}`, ids, null);
    },

    save (item, changes) {
        return HttpWrapper.put(`${endpoints.DEPOSIT_SLIPS}/`, { data: item, changes}, null);
    },

    saveDetail (depositSlipID, ids) {
        return HttpWrapper.put(`${endpoints.DEPOSIT_SLIPS}/detail/${depositSlipID}`, ids, null);
    },

    depositAvailability (ids) {
        return HttpWrapper.put(`${endpoints.DEPOSIT_SLIPS}/depositAvailability`, ids, null);
    }

};