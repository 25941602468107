import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getSourceOfBusinesses(){
        return HttpWrapper.get(`${endpoints.SOURCESOFBUSINESS}`);
    },
    saveSourceOfBusiness (sourceOfBusiness, changes) {        
        return HttpWrapper.put(`${endpoints.SOURCESOFBUSINESS}`, { data: sourceOfBusiness, changes} );              
    },
    deleteSourcesOfBusinesses(ids) {
        return HttpWrapper.post(`${endpoints.SOURCESOFBUSINESS}/delete`, ids);
    },
    deleteSourceOfBusiness (sourceOfBusinessID) {              
        return HttpWrapper.delete(`${endpoints.SOURCESOFBUSINESS}/${sourceOfBusinessID}`);              
    }
};
