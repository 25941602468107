import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getPolicyFormTypes(){
        return HttpWrapper.get(`${endpoints.POLICY_FORM_TYPES}`);
    },
    savePolicyFormType(policyFormtype, changes = null) {
        return HttpWrapper.put(`${endpoints.POLICY_FORM_TYPES}`, { data: policyFormtype, changes} );              
    },
    deletePolicyFormType (policyFormTypeId) {              
        return HttpWrapper.delete(`${endpoints.POLICY_FORM_TYPES}/${policyFormTypeId}`);              
    },
    deletePolicyFormTypes (ids) {
        return HttpWrapper.post(`${endpoints.POLICY_FORM_TYPES}/delete`, ids);
    },
    toggleIsInactive(policyFormTypeIds) {
        return HttpWrapper.post(`${endpoints.POLICY_FORM_TYPES}/toggleisinactive`, policyFormTypeIds);
    },
};
