import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    get(hudVersion){
        return HttpWrapper.get(`${endpoints.DEFAULTHUDLINE}/${hudVersion}`);
    },
    save (defaultHudLineDataDto) {
        return HttpWrapper.post(`${endpoints.DEFAULTHUDLINE}`, { data: defaultHudLineDataDto} );
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.DEFAULTHUDLINE}/delete`, ids);
    },
};
