import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getRecordingRejections(){
        return HttpWrapper.get(`${endpoints.RECORDING_REJECTIONS}`);
    },
    saveRecordingRejection(recordingRejection, changes = null) {
        return HttpWrapper.put(`${endpoints.RECORDING_REJECTIONS}`, { data: recordingRejection, changes} );              
    },
    toggleIsInactive(recordingRejectionIds) {
        return HttpWrapper.post(`${endpoints.RECORDING_REJECTIONS}/toggleisinactive`, recordingRejectionIds);
    },
};
