import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getRecordingTypes(){
        return HttpWrapper.get(`${endpoints.RECORDING_TYPES}`);
    },
    saveRecordingType(recordingtype, changes = null) {
        return HttpWrapper.put(`${endpoints.RECORDING_TYPES}`, { data: recordingtype, changes} );              
    },
    deleteRecordingType (recordingtypeId) {              
        return HttpWrapper.delete(`${endpoints.RECORDING_TYPES}/${recordingtypeId}`);              
    },
    deleteRecordingTypes (ids) {
        return HttpWrapper.post(`${endpoints.RECORDING_TYPES}/delete`, ids);
    },
    toggleIsInactive(recordingtypeIds) {
        return HttpWrapper.post(`${endpoints.RECORDING_TYPES}/toggleisinactive`, recordingtypeIds);
    },
};
