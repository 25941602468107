import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getEscrowUnits(){
        return HttpWrapper.get(`${endpoints.ESCROWUNITS}`)
    },

    getCheckRange(escrowUnitID){
        return HttpWrapper.get(`${endpoints.ESCROWUNITS}/checkRange/${escrowUnitID}`)
    },

    saveEscrowUnit (escrowUnit) {
        return HttpWrapper.put(`${endpoints.ESCROWUNITS}`, escrowUnit);
    },

    deleteEscrowUnit (escrowUnitID) {
        return HttpWrapper.delete(`${endpoints.ESCROWUNITS}/${escrowUnitID}`);
    },

    deleteCheck (escrowUnitCheckID) {
        return HttpWrapper.delete(`${endpoints.ESCROWUNITS}/deleteCheck/${escrowUnitCheckID}`);
    },

    convertSignature (imageFile) {
        return HttpWrapper.post(`${endpoints.ESCROWUNITS}/convertImage`, imageFile);
    },

    saveCheckRanges (data) {
        return HttpWrapper.post(`${endpoints.ESCROWUNITS}/checkRanges`, data, null);
    },
};
