import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getAll(){
        return HttpWrapper.get(`${endpoints.LOSSADVANCEREASONCATEGORIES}`);
    },
    saveLossAdvanceReasonCategory (lossAdvanceReasonCategory, changes) {
        return HttpWrapper.put(`${endpoints.LOSSADVANCEREASONCATEGORIES}`, { data: lossAdvanceReasonCategory, changes} );              
    },
    deleteLossAdvanceReasonCategory (lossAdvanceReasonCategoryID) {              
        return HttpWrapper.delete(`${endpoints.LOSSADVANCEREASONCATEGORIES}/${lossAdvanceReasonCategoryID}`);              
    },
    deleteLossAdvanceReasonCategories (ids) {
        return HttpWrapper.post(`${endpoints.LOSSADVANCEREASONCATEGORIES}/delete`, ids);
    },
    activateLossAdvanceReasonCategories(lossAdvanceReasonCategoryID) {
        return HttpWrapper.post(`${endpoints.LOSSADVANCEREASONCATEGORIES}/activateLossAdvanceReasonCategories`, lossAdvanceReasonCategoryID);
    },

    getCategoryLookups() {
        return HttpWrapper.get(`${endpoints.LOSSADVANCEREASONCATEGORIES}/lookups`);
    }
};
