import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default{
    getConfiguration () { return HttpWrapper.get(`${endpoints.CM_E_REMIT}/configuration`); },
    getConfigurationData () { return HttpWrapper.get(`${endpoints.CM_E_REMIT}/configurationData`); },
    getEnterprise (enterpriseGuid) { return HttpWrapper.get(`${endpoints.CM_E_REMIT}/enterprise/${enterpriseGuid}`); },
    searchFiles (request) { return HttpWrapper.post(`${endpoints.CM_E_REMIT}/searchFiles`, request, null); },
    searchRequests (request) { return HttpWrapper.post(`${endpoints.CM_E_REMIT}/searchRequests`, request, null); },
    submitFiles (relationshipKey, request) { return HttpWrapper.post(`${endpoints.CM_E_REMIT}/${relationshipKey}/submitFiles`, request, null); },
}