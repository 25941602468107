import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getEpicorProducts(){
        return HttpWrapper.get(`${endpoints.EPICORPRODUCTS}`);
    },
    saveEpicorProduct (epicorProduct, changes) {        
        return HttpWrapper.put(`${endpoints.EPICORPRODUCTS}`, { data: epicorProduct, changes} );              
    },
    deleteEpicorProduct (epicorProductID) {              
        return HttpWrapper.delete(`${endpoints.EPICORPRODUCTS}/${epicorProductID}`);              
    },
};
