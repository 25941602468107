import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
   
    getEpicorBankCodes(){
        return HttpWrapper.get(`${endpoints.EPICORBANKCODES}`);
    },
    saveEpicorBankCode (epicorBankCode, changes) {        
        return HttpWrapper.put(`${endpoints.EPICORBANKCODES}`, { data: epicorBankCode, changes} );              
    },
    deleteEpicorBankCode (epicorBankCodeID) {              
        return HttpWrapper.delete(`${endpoints.EPICORBANKCODES}/${epicorBankCodeID}`);              
    },
};
