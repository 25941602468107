import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    get(){
        return HttpWrapper.get(`${endpoints.AUTORECONMAPPINGS}`);
    },
    save (eBankReconciliation, changes) {
        return HttpWrapper.put(`${endpoints.AUTORECONMAPPINGS}`, { data: eBankReconciliation, changes} );
    },
    delete (ids) {
        return HttpWrapper.post(`${endpoints.AUTORECONMAPPINGS}/delete`, ids);
    },

};
