import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';
import { SETTLEMENT_TYPE } from '@settlement/models';    

export default{
    getRecordsByLoan (loanId) { return HttpWrapper.get(`${endpoints.LOANCOST_CHARGES}/loan/${loanId}`); },
    deleteRecord (id) { return HttpWrapper.delete(`${endpoints.LOANCOST_CHARGES}/${id}`); },
    saveRecords (records) { return HttpWrapper.post(`${endpoints.LOANCOST_CHARGES}`, records); },
    saveDeleteRecords (items, deletedItems) { return HttpWrapper.post(`${endpoints.LOANCOST_CHARGES}/fullUpdate`, {items, deletedItems}); },
    mapData(loanId) { return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf`); },
    mapHudData(loanId) { return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud1974`); },
    mapSettlementData(settlementType, loanId) {
        switch (settlementType) {
            case SETTLEMENT_TYPE.CDF:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createcdf`);
            case SETTLEMENT_TYPE.HUD_1974:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud1974`);
            case SETTLEMENT_TYPE.HUD_2010:
                return HttpWrapper.post(`${endpoints.SETTLEMENT_STATEMENT}/${loanId}/createHud2010`);
        }
    },

    getNetDisbursementsByLoan (loanId) { return HttpWrapper.get(`${endpoints.LOANCOST_CHARGES}/netDisbursements/loan/${loanId}`); },
    // saveDeleteNetDisbursements (items, deletedItems) { return HttpWrapper.post(`${endpoints.LOANCOST_CHARGES}/netDisbursements/fullUpdate`, {items, deletedItems}); },
}