import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getSurveys () { return HttpWrapper.get(endpoints.SURVEYS); },
    getSurveysByState (state) { return HttpWrapper.get(`${endpoints.SURVEYS}/getByState/${state}`); },
    getSurveyById (surveyId) { return HttpWrapper.get(`${endpoints.SURVEYS}/${surveyId}`); },
    deleteSurvey (surveyId) { return HttpWrapper.delete(`${endpoints.SURVEYS}/${surveyId}`); },
    saveSurvey (survey) { return HttpWrapper.post(`${endpoints.SURVEYS}/save`, survey); }
};

